import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  Button,
  ButtonGroup,
  Form,
  Table,
  ToggleButton,
} from "react-bootstrap";
import { helpHttp } from "../../helpers/helpHttp";
import Pagination from "react-bootstrap/Pagination";
import Loader from "../../common/loader/Loader";
import "./Index.css";

const OPTION_SEARCH = {
  UseName: "1",
  Estado: "2",
  Ganador: "3",
  Id: "4",
};

const OPTION_SELECT = {
  null: "null.",
  EsperandoAcercarse: "Esperando acercarse.",
  EstaPagando: "Está pagando.",
  PagoFinalizado: "Pago finalizado.",
  ErrorEnElPago: "Error en el pago.",
};

const OPTION_BUTTONS = [
  { name: "UseName", value: OPTION_SEARCH.UseName },
  { name: "Estado", value: OPTION_SEARCH.Estado },
  { name: "Ganador", value: OPTION_SEARCH.Ganador },
  { name: "Id", value: OPTION_SEARCH.Id },
];

export default function Auctions() {
  const [auctions, setAuctions] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const [radioValue, setRadioValue] = useState(OPTION_SEARCH.UseName);
  const [search, setSearch] = useState("");
  const [addSearch, setAddSearch] = useState("");
  const [select, setSelect] = useState(OPTION_SELECT.null);
  const [loaderSearch, setLoaderSearch] = useState(true);

  let active = activePage;
  let items = [];
  for (let number = 1; number <= maxPages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === active}
        onClick={() => setActivePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  const changeSelect = (e) => {
    setSelect(e.target.value);
  };

  useEffect(() => {
    setLoaderSearch(true);
    const URL_AUCTIONS = `${process.env.REACT_APP_API_DOMAIN}${
      process.env.REACT_APP_GET_AUCTIONS
    }?page=${activePage ? activePage : 1}&pagesize=30${addSearch}`;
    let options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    helpHttp()
      .get(URL_AUCTIONS, options)
      .then((res) => {
        setAuctions(res);
        setActivePage(res.pageNumber);
        setMaxPages(res.totalPages ? res.totalPages : 1);
        setLoaderSearch(false);
      });
  }, [activePage, addSearch]);

  const sendSearch = (e) => {
    e.preventDefault();
    let option;
    if (radioValue === OPTION_SEARCH.UseName) option = "userName";
    if (radioValue === OPTION_SEARCH.Estado) option = "state";
    if (radioValue === OPTION_SEARCH.Ganador) option = "winnerUserName";
    if (radioValue === OPTION_SEARCH.Id) option = "id";

    setAddSearch(
      `&${option}=${radioValue === OPTION_SEARCH.Estado ? select : search}`
    );
  };

  return (
    <>
      <div className="d-flex justify-content-end container-search  mb-3 mt-4">
        <ButtonGroup>
          {OPTION_BUTTONS.map((radio, idx) => (
            <ToggleButton
              key={idx}
              id={`radio-${idx}`}
              type="radio"
              variant="secondary"
              name="radio"
              value={radio.value}
              checked={radioValue === radio.value}
              onChange={(e) => setRadioValue(e.currentTarget.value)}
              className="button-group"
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ButtonGroup>

        <Form className="d-flex ms-4" onSubmit={sendSearch}>
          {radioValue !== OPTION_SEARCH.Estado && (
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          )}
          {radioValue === OPTION_SEARCH.Estado && (
            <Form.Select
              aria-label="Default select example"
              className="select custom-select me-1"
              onChange={changeSelect}
            >
              <option value="null.">No termino</option>
              <option value="Esperando acercarse.">No se acerco</option>
              <option value="Está pagando.">Esta pagando</option>
              <option value="Error en el pago.">Pago cancelado</option>
              <option value="Pago finalizado.">Pago terminado</option>
            </Form.Select>
          )}
          <Button
            variant="outline-success"
            type="submite"
            className="button-group mt-2"
          >
            Search
          </Button>
        </Form>
      </div>
      <Table striped bordered hover className="hoverable" responsive="sm">
        <thead>
          <tr>
            <th>User</th>
            <th>Precio inicial</th>
            <th>Precio final</th>
            <th>Patente</th>
            <th>Retiro del dinero</th>
            <th>Ganador</th>
          </tr>
        </thead>
        {auctions && (
          <tbody>
            {auctions.content?.map((auction) => (
              <tr key={auction.id}>
                <td>{auction.username}</td>
                <td>{auction.initialPrice}</td>
                <td>{auction.finalPrice}</td>
                <td>{auction.plate}</td>
                <td>{auction.moneyRetired ? "Si" : "No"}</td>
                <td>{auction.winnerUserName}</td>
              </tr>
            ))}
          </tbody>
        )}
        {auctions.content?.length < 1 ? (
          <tbody className="text-center">
            <tr>
              <td colSpan="6">No hay datos</td>
            </tr>
          </tbody>
        ) : null}
      </Table>
      {loaderSearch && <Loader />}
      <div className="d-flex justify-content-center">
        <Pagination>{items}</Pagination>
      </div>
    </>
  );
}
