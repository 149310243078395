import { Navigate, Outlet } from "react-router-dom";


export const ProtectedRouteNoSession = ({children}) => {

    let tokenExist = localStorage.getItem('token') ? true : false;

    if (!tokenExist) {
        return <Navigate to={`${process.env.REACT_APP_LOGIN_FRONT}`} replace />;
    }

  return children ? children : <Outlet />;
};