import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../assets/img/new/Posta.png";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { ImgBackIndex } from "../../util/constant/constant";

const Index = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [nro, setNro] = useState(40);
  let navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (width < 768) {
      setNro(20);
    } else {
      setNro(40);
    }
  }, [width]);

  const goToLogin = () => {
    navigate(`${process.env.REACT_APP_LOGIN_FRONT}`);
  };
  const goToRegister = () => {
    navigate(`${process.env.REACT_APP_REGISTER_FRONT}`);
  };

  return (
    <ImgBackIndex className="container-index">
      <div className="d-flex align-items-center flex-column abs-center">
        <h1 className="text-white text-welcome">¡Bienvenido!</h1>
        <img src={logo} className="logo" alt="logo" />
        <button
          type="button"
          className="waves-effect waves-light btn text-white rounded-pill btn-init"
          onClick={goToLogin}
        >
          {"\xa0".repeat(nro)}Comenzar{"\xa0".repeat(nro)}
        </button>
        <h4 className="text-white text-register">
          ¿No tenes una cuenta?{" "}
          <b className="btn-register" onClick={goToRegister}>
            Registrate
          </b>
        </h4>
      </div>
    </ImgBackIndex>
  );
};

export default Index;
