import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  Button,
  ButtonGroup,
  Form,
  Pagination,
  Table,
  ToggleButton,
} from "react-bootstrap";
import { helpHttp } from "../../helpers/helpHttp";
import Loader from "../../common/loader/Loader";
import "./Index.css";
import ModalAuction from "../../common/modals/ModalAuction";
import ModalMP from "../../common/modals/ModalMP";
import ModalConfirm from "../../common/modals/ModalConfirm";

const OPTION_FILTER = {
  paid: "1",
  noPaid: "2",
  all: "3",
};

const OPTION_BUTTONS = [
  { name: "Pagados", value: OPTION_FILTER.paid },
  { name: "No Pagados", value: OPTION_FILTER.noPaid },
  { name: "Todos", value: OPTION_FILTER.all },
];

export default function PendingPayments() {
  const [pendingPayments, setPendingPayments] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const [addSearch, setAddSearch] = useState("");
  const [search, setSearch] = useState("");
  const [modalViewMore, setModalViewMore] = useState(false);
  const [loaderSearch, setLoaderSearch] = useState(true);
  const [loaderSearchId, setLoaderSearchId] = useState(false);
  const [auction, setAuction] = useState(null);
  const [paidItems, setPaidItems] = useState([]);
  const [radioValue, setRadioValue] = useState(OPTION_FILTER.all);
  const [mp, setMp] = useState(null);
  const [mpItem, setMpItem] = useState(null);
  const [loaderMp, setLoaderMp] = useState(false);
  const [modalMp, setModalMp] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [selectedBidId, setSelectedBidId] = useState(null);

  let active = activePage;
  let items = [];
  for (let number = 1; number <= maxPages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === active}
        onClick={() => setActivePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  useEffect(() => {
    setLoaderSearch(true);
    const URL_PANDING_PAYMENTS = `${process.env.REACT_APP_API_DOMAIN}${
      process.env.REACT_APP_GET_PENDING_PAYMENT
    }?page=${activePage ? activePage : 1}${addSearch}`;
    let options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    helpHttp()
      .get(URL_PANDING_PAYMENTS, options)
      .then((res) => {
        /* console.log(res); */
        setPendingPayments(res);
        setActivePage(res.pageNumber);
        setMaxPages(res.totalPages !== 0 ? res.totalPages : 1);
        setLoaderSearch(false);
      })
      .catch((e) => {
        setAuction([]);
      });
  }, [activePage, addSearch]);

  useEffect(() => {
    const URL_MP = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_MP_LIST}?fromTime=12MONTH&status=approved&limit=1000&offset=0`;
    let options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    helpHttp()
      .get(URL_MP, options)
      .then((res) => {
        let array = JSON.parse(res);
        /* console.log(array); */
        setMp(array.results);
      });
  }, []);

  const sendSearch = (e) => {
    e.preventDefault();
    setActivePage(1);
    setAddSearch(`&bidId=${search}`);
  };

  const searchbidId = (bidId) => {
    setAuction(null);
    setModalViewMore(true);
    setLoaderSearchId(true);
    const URL_AUCTIONS = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_GET_AUCTIONS}?id=${bidId}`;
    let options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    helpHttp()
      .get(URL_AUCTIONS, options)
      .then((res) => {
        setAuction(res.content[0]);
        setLoaderSearchId(false);
      })
      .catch((e) => {
        setAuction([]);
      });
  };

  const checkPayment = () => {
    setPaidItems([...paidItems, selectedBidId]);
    const URL_AUCTIONS = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_PAY_BID}?id=${selectedBidId}`;
    let options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    helpHttp().get(URL_AUCTIONS, options);
    setModalConfirm(false);
  };

  const handlerFilter = (valueFilter) => {
    setRadioValue(valueFilter);
    setActivePage(1);
    if (valueFilter === OPTION_FILTER.paid) setAddSearch("&paid=true");
    if (valueFilter === OPTION_FILTER.noPaid) setAddSearch("&paid=false");
    if (valueFilter === OPTION_FILTER.all) setAddSearch("");
  };

  const searchMpId = (bidId) => {
    setModalMp(true);
    setLoaderMp(true);

    const auctionFilter = mp?.find(
      (auction) => auction.description === `Subasta ${bidId}`
    );

    if (auctionFilter === undefined) {
      setMpItem(null);
    } else {
      setMpItem(auctionFilter);
    }
    setLoaderMp(false);
  };

  function formatDateTime(dateTimeString) {
    const date = new Date(dateTimeString);

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();

    return `${hours}:${minutes} ${day}-${month}-${year}`;
  }

  const handleConfirm = (bidId) => {
    setModalConfirm(true);
    setSelectedBidId(bidId);
  };

  return (
    <>
      <ModalAuction
        modalViewMore={modalViewMore}
        setModalViewMore={setModalViewMore}
        loaderSearchId={loaderSearchId}
        auction={auction}
      />

      <ModalMP
        modalMp={modalMp}
        setModalMp={setModalMp}
        loaderMp={loaderMp}
        mpItem={mpItem}
        formatDateTime={formatDateTime}
      />

      <ModalConfirm
        modalConfirm={modalConfirm}
        setModalConfirm={setModalConfirm}
        text={"Estas seguro que deseas marcar como check este pago pendiente"}
        action={checkPayment}
      />

      <div className="d-flex justify-content-between container-search  mb-3 mt-4">
        <ButtonGroup>
          {OPTION_BUTTONS.map((radio, idx) => (
            <ToggleButton
              key={idx}
              id={`radio-${idx}`}
              type="radio"
              variant="secondary"
              name="radio"
              value={radio.value}
              checked={radioValue === radio.value}
              onChange={(e) => handlerFilter(e.currentTarget.value)}
              className="button-group"
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ButtonGroup>

        <Form className="d-flex ms-4" onSubmit={sendSearch}>
          <Form.Control
            type="search"
            placeholder="Search by Id"
            className="me-2"
            aria-label="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Button
            variant="outline-success"
            type="submite"
            className="button-group mt-2"
          >
            Search
          </Button>
        </Form>
      </div>
      <Table striped bordered hover className="hoverable pe-2" responsive="sm">
        <thead>
          <tr>
            <th>id</th>
            <th>Precio</th>
            <th>CBU</th>
            <th>Actions</th>
            <th>Mp</th>
            <th>Check</th>
          </tr>
        </thead>
        {pendingPayments && (
          <tbody>
            {pendingPayments.content?.map((pendingPayment) => (
              <tr key={pendingPayment.id}>
                <td>{pendingPayment.bidId}</td>
                <td>{pendingPayment.amount}</td>
                <td>{pendingPayment.cbu}</td>
                <td>
                  <Button
                    variant="secondary"
                    className="button-action"
                    onClick={() => searchbidId(pendingPayment.bidId)}
                  >
                    Ver mas
                  </Button>
                </td>
                <td>
                  <Button
                    variant="primary"
                    onClick={() => searchMpId(pendingPayment.bidId)}
                  >
                    MP
                  </Button>
                </td>
                <td className="td-check">
                  {pendingPayment.paid ||
                  paidItems.includes(pendingPayment.bidId) ? (
                    "✅"
                  ) : (
                    <div
                      type="checkbox"
                      className="checkPayment checkbox-square text-center"
                      onClick={() => handleConfirm(pendingPayment.bidId)}
                    ></div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        )}
        {pendingPayments.content?.length < 1 ? (
          <tbody className="text-center">
            <tr>
              <td colSpan="6">No hay datos</td>
            </tr>
          </tbody>
        ) : null}
      </Table>
      {loaderSearch && <Loader />}
      <div className="d-flex justify-content-center">
        <Pagination>{items}</Pagination>
      </div>
    </>
  );
}
