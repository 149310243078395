import React, { useState, useEffect } from "react";
import { helpHttp } from "../../helpers/helpHttp";
import LoaderLogo from "../../common/loader/LoaderLogo";
import Marker from "../../common/google_map/Marker";
import GoogleMap from "../../common/google_map/GoogleMap";
import InfoWindow from "../../common/google_map/InfoWindow";
import "./map.css";
import Profile from "../../common/profile/Profile";
import { useAuction } from "../../hooks/useAuction";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { NotificationManager } from "react-notifications";

export default function SimpleMap() {
  const initialDb = [
    { userName: "No hay datos", latitud: -34.5, logitud: -58.5, show: false },
  ];
  const FORM_PAY = "payment-form_pay";

  const [show, setShow] = useState(false);
  const [db, setDb] = useState(initialDb);
  const [place, setPlace] = useState(null);
  const [color, setColor] = useState(null);
  const [iamOwner, setIamOwner] = useState(false);
  const [iPay, setIPay] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [history, setHistory] = useState([]);
  const [CBU, setCBU] = useState("");
  const [CBUError, setCBUError] = useState(null);
  const [idFinish, setIdFinish] = useState(null);
  const [center, setCenter] = useState([null, null, false]);
  const [inAnAuction, setInAnAuction] = useState([false, null, false]);
  const [showNotificationsClose, setShowNotificationsClose] = useState({
    state: false,
    timeOn: null,
  });
  const [showNotificationsPay, setShowNotificationsPay] = useState({
    state: false,
    timeOn: null,
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  /* const handleIamOwner = () => {
    handleCloseModal();
    setIPay(false);
    setIamOwner(false);
  }; */

  const {
    joined,
    setPrice,
    setStartingPrice,
    setDays,
    setHours,
    setMinutes,
    setDescription,
    actualBit,
    auction,
    checkForBiddings,
    checkForBiddings2,
    finish_bid,
    connection,
    check_,
    check_2,
    stateConnection,
    iamWinner,
    /* handleCloseModal, */
    sendBid,
    startBidding,
    freeParking,
    free,
    finishAuction,
    takeFree,
    getFreeParking_,
    payCheck,
    notificationFinish,
    itsPaid,
    ownerWithoutAuction,
    handleCloseModalOwnerWithoutAuction,
    itsPaidAuction,
    quitFromBid_,
    fixCheck2,
  } = useAuction(
    setColor,
    inAnAuction,
    setInAnAuction,
    setIdFinish,
    setIamOwner
  );

  useEffect(() => {
    /* const userName = localStorage.getItem("user"); */
    /*  const initialDb = [
      { userName: "No hay datos", latitud: -34.5, logitud: -58.5, show: false },
    ]; */
    const URL_NEARBY = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_NEARBY}`;
    let options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    let interval = null;

    interval = setInterval(() => {
      try {
        helpHttp()
          .get(URL_NEARBY, options)
          .then((res) => {
            if (Array.isArray(res)) {
              /* if (check_2) {
                const { winners, owners, inAnAuction } = check_2;
                console.log(winners, "winners");
                console.log(owners, "owners");
                console.log(inAnAuction, "inAnAuction");

                for (let index = 0; index < res.length; index++) {
                  let indexWinner = res.indexOf(e => e.userName === winners[index])
                  if(indexWinner !== -1){
                    res[indexWinner].color = "green2"
                  }
                  let indexOwner = res.indexOf(e => e.userName === owners[index])
                  if(indexOwner !== -1){
                    res[indexWinner].color = "blue2"
                  }
                  let indexInAnAuction = res.indexOf(e => e.userName === inAnAuction[index])
                  if(indexInAnAuction !== -1){
                    res[indexWinner].color = "red2"
                  }                  
                }
              } */

              /* if (!itsPaidAuction && idFinish !== null) {
                handlerNotification();
                itsPaid(idFinish);
              } */

              setDb(res);

              /* let colorPut = color; */

              if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                  if (Array.isArray(res)) {
                    const index = res.findIndex(
                      (e) => e.userName === localStorage.getItem("user")
                    );

                    const pos = {
                      lat: position.coords.latitude,
                      lng: position.coords.longitude,
                    };

                    /* if (colorPut === null && index !== -1) {
                      colorPut = res[index].string1;
                    } */

                    if (!center[2] && res.length > 0) {
                      if (res[index] !== undefined) {
                        setCenter([
                          parseFloat(res[index].latitud),
                          parseFloat(res[index].logitud),
                          true,
                        ]);
                      }
                    }

                    let data = {
                      logitud: `${pos.lng}`,
                      latitud: `${pos.lat}`,
                      string1: "210.0F",
                    };
                    let api = helpHttp();

                    let options = {
                      body: data,
                      headers: {
                        "Content-Type": "application/json",
                        Authorization:
                          "Bearer " + localStorage.getItem("token"),
                      },
                    };

                    let url = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_UPDATE_LOCATION}`;

                    api.put(url, options).then((res) => {});

                    /* if (iamWinner.state === true) {
                      payCheck(
                        iamWinner.id,
                        position.coords.latitude,
                        position.coords.longitude
                      );
                    } */
                  }
                });
              } else {
                console.log("No se pudo obtener la ubicación");
              }
            } else {
              clearInterval(interval);
              if (res.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                localStorage.removeItem("id-auction");
                window.location.href = `${process.env.REACT_APP_LOGIN_FRONT}`;
              }
            }
          });
      } catch (e) {
        clearInterval(interval);
      }
    }, 1000);

    /* const handlerNotification = () => {
      if (notificationFinish === "Get closer to the seller") {
        if (showNotificationsClose.timeOn !== null) {
          if (
            showNotificationsClose.timeOn.getTime() + 60000 <
            new Date().getTime()
          ) {
            setShowNotificationsClose({
              state: false,
              timeOn: new Date(),
            });
          }
        }

        if (!showNotificationsClose.state) {
          if (iamOwner) {
            NotificationManager.warning(
              "Espera a que se acerque el vehículo",
              "",
              59000
            );

            setShowNotificationsClose({
              state: true,
              timeOn: new Date(),
            });
          } else {
            NotificationManager.success("Ganaste la subasta", "", 59000);
            NotificationManager.warning(
              "Acércate al estacionamiento",
              "",
              59000
            );
            setShowNotificationsClose({
              state: true,
              timeOn: new Date(),
            });
          }
        }
      }

      if (notificationFinish === "Pay available") {
        if (showNotificationsPay.timeOn !== null) {
          if (
            showNotificationsPay.timeOn.getTime() + 60000 <
            new Date().getTime()
          ) {
            setShowNotificationsPay({
              state: false,
              timeOn: new Date(),
            });
          }
        }

        if (!showNotificationsPay.state) {
          if (iamOwner) {
            NotificationManager.warning(
              "Espera a que realice el pago",
              "",
              59000
            );
            setShowNotificationsPay({
              state: true,
              timeOn: new Date(),
            });
          } else {
            setIPay(true);
            setShowNotificationsPay({
              state: true,
              timeOn: new Date(),
            });
          }
        }
      }
    }; */

    return () => clearInterval(interval);
  }, [
    connection,
    joined,
    check_,
    checkForBiddings,
    check_2,
    checkForBiddings2,
    notificationFinish,
    showNotificationsPay,
    showNotificationsClose,
    itsPaidAuction,
    idFinish,
  ]);

  /* useEffect(() => {
    const URL_MP = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_MP_100}`;
    let options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    helpHttp()
      .get(URL_MP, options)
      .then((res) => {
        if (iPay) {
          const script = document.createElement("script");
          script.src =
            "https://www.mercadopago.com.ar/integrations/v1/web-payment-checkout.js";
          script.type = "text/javascript";
          script.setAttribute("data-preference-id", res);
          const form = document.getElementById(FORM_PAY);
          form.appendChild(script);
        }
      });
  }, [iPay]); */

  const startAuction = () => {
    const index = db.findIndex(
      (e) => e.userName === localStorage.getItem("user")
    );
    db[index].show = true;
    setPlace(db[index]);

    var intro = document.getElementById("intro");

    if (intro.style.display === "none") {
      intro.style.display = "block";
    } else {
      intro.style.display = "none";
    }
  };

  const handlerWindow = (children) => {
    const index = db.findIndex(
      (e) => e.userName === children.currentTarget.getAttribute("alt")
    );
    db[index].show = true;
    setPlace(db[index]);

    var intro = document.getElementById("intro");

    if (intro.style.display === "none") {
      intro.style.display = "block";
    } else {
      intro.style.display = "none";
    }
  };

  /* const handlerWindow2 = (children) => {
    const index = free.findIndex(
      (e) => `${e.id}` === children.currentTarget.getAttribute("alt")
    );
    free[index].show = true;
    setPlace(free[index]);

    var intro = document.getElementById("intro");

    if (intro.style.display === "none") {
      intro.style.display = "block";
    } else {
      intro.style.display = "none";
    }
  }; */

  useEffect(() => {
    if (showHistory) {
      const URL_HISTORY = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_GET_HISTORY}`;
      let options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      helpHttp()
        .get(URL_HISTORY, options)
        .then((res) => {
          setHistory(res.content);
        });
    }
  }, [showHistory, history]);

  const claim = (id, price) => {
    if (CBUError === "") {
      const URL_CLAIM = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_CLAIM_AUCTION}?bidId=${id}&CBU=${CBU}&amount=${price}`;
      let options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      helpHttp()
        .post(URL_CLAIM, options)
        .then((res) => {
          console.log(res);
        });
    } else if (CBUError === null) {
      setCBUError("El campo mail es requerido");
    }
  };

  const validationsCBU = (e) => {
    let value = e.target.value;
    setCBU(value);

    if (!value.trim()) {
      setCBUError("El campo mail es requerido");
    } else {
      setCBUError("");
    }
  };

  return (
    <>
      <Modal show={showHistory} onHide={() => setShowHistory(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Historial de subastas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="input-field col s6">
            <input
              type="email"
              className="white-text"
              id="email"
              name="email"
              onChange={validationsCBU}
              value={CBU}
            />
            <label htmlFor="userName" className="white-text">
              CBU
            </label>
            {CBUError && (
              <span className="helper-text red-text black">{CBUError}</span>
            )}
          </div>
          {history.length > 0 ? (
            history?.map((a) => (
              <>
                <span className="d-flex justify-content-between" key={a.id}>
                  <p>
                    Id:{a.id} Descripcion: {a.description} - precio final:
                    {a.finalPrice}
                  </p>
                  {a.askForPay ? (
                    <span className="text-right"> Solicitado</span>
                  ) : (
                    <span
                      className="pointer claim text-right"
                      onClick={() => claim(a.id, a.finalPrice)}
                    >
                      {" "}
                      Reclamar
                    </span>
                  )}
                </span>
              </>
            ))
          ) : (
            <span>No tienes subastas</span>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowHistory(false)}>
            cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Modal show={iPay} className="modal">
        <Modal.Header>
          <Modal.Title>Subasta terminada</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Realiza el pago por {iamWinner.price}$</p>
          <script src="https://sdk.mercadopago.com/js/v2"></script>
          <form id={FORM_PAY} method="GET" />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={ownerWithoutAuction}
        onHide={handleCloseModalOwnerWithoutAuction}
      >
        <Modal.Header closeButton>
          <Modal.Title>Subasta terminada</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>No se realizaron pujas</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseModalOwnerWithoutAuction}
          >
            cerrar
          </Button>
        </Modal.Footer>
      </Modal> */}

      {stateConnection[0] && stateConnection[1] && <LoaderLogo />}
      {!stateConnection[0] && !stateConnection[1] && (
        <div className="container_buttons">
          <button
            className="btn-floating btn-large waves-effect me-3 btn-menu"
            onClick={handleShow}
          >
            <i className="material-icons">menu</i>
          </button>
        </div>
      )}

      <div id="intro" className="asd" style={{ display: "none" }}>
        {place ? (
          <InfoWindow
            place={place}
            auction={auction}
            actualBit={actualBit}
            sendBid={sendBid}
            setPrice={setPrice}
            setStartingPrice={setStartingPrice}
            setDays={setDays}
            setHours={setHours}
            setMinutes={setMinutes}
            setDescription={setDescription}
            startBidding={startBidding}
            setInAnAuction={setInAnAuction}
            finish_bid={finish_bid}
            takeFree={takeFree}
            freeParking={freeParking}
            getFreeParking_={getFreeParking_}
            free={free}
            finishAuction={finishAuction}
            inAnAuction={inAnAuction}
            quitFromBid_={quitFromBid_}
          />
        ) : null}
      </div>

      <Profile
        show={show}
        handleClose={handleClose}
        startAuction={startAuction}
        setShowHistory={setShowHistory}
      />

      {!stateConnection[0] && !stateConnection[1] && db && (
        <div style={{ height: "100vh", width: "100%" }}>
          <GoogleMap
            defaultZoom={center[0] ? 15 : 10}
            defaultCenter={[
              center[0] ? center[0] : parseFloat("-34.75"),
              center[1] ? center[1] : parseFloat("-58.68"),
            ]}
            yesIWantToUseGoogleMapApiInternals={true}
          >
            {db.map((location) => (
              <Marker
                id={location.userName}
                key={location.userName}
                text={location.userName}
                free={false}
                lat={location.latitud}
                lng={location.logitud}
                show={location.show}
                onClick={handlerWindow}
                place={location}
                color={location.color}
                iam={
                  location.userName === localStorage.getItem("user")
                    ? location.userName
                    : null
                }
              ></Marker>
            ))}
            {/* {free.map((free) => (
              <Marker
                id={free.id}
                key={free.id}
                text={`${free.id}`}
                free={true}
                lat={free.latitude}
                lng={free.longitude}
                show={free.show}
                onClick={handlerWindow2}
                place={free}
                color={"blue2"}
              ></Marker>
            ))} */}
          </GoogleMap>
        </div>
      )}
    </>
  );
}
