import React from 'react';
import logo from "../../assets/img/new/Posta.png";
import './Loader.css'; // Importar el archivo CSS del loader

const LoaderLogo = () => {
  return (
    <div className="loader-container">
      <img src={logo} alt="Logo" className="logo-loader" />
    </div>
  );
};

export default LoaderLogo;

