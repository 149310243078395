import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Container, Modal, Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import logo from "../../assets/img/new/Posta.png";
import { useNavigate } from "react-router-dom";
import { ImgBackPage } from "../../util/constant/constant";

const EmailChangePassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(
    "Ingrese el mail con el que esta registrado"
  );
  const [loading, setLoading] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalError, setModalError] = useState(false);

 /*  const [errorSend, setErrorSend] = useState(false); */

  let navigate = useNavigate();

  /* const validationsForm = (form) => {
    let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}([/.]\w{2,})?$/;

    if (!email.trim()) {
      setError("El campo email es requerido");
    } else if (!regexEmail.test(email.trim())) {
      setError("El campo email es incorrecto (ejemplo@ejemplo.com)");
    }
  }; */

  const handleChangeEmail = (e) => {
    let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}([/.]\w{2,})?$/;

    let value = e.target.value;
    setEmail(value);

    if (!value.trim()) {
      setError("El campo mail es requerido");
    } else if (!regexEmail.test(value.trim())) {
      setError("Formato incorrecto, ej: ejemplo@gmail.com");
    } else {
      setError("");
    }
  };

  const goToLogin = () => {
    setModalSuccess(false);
    setModalError(false);
    navigate(`${process.env.REACT_APP_LOGIN_FRONT}`);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (error === "") {
      setLoading(true);
      let encodedURL = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_SEND_PASSWORD}?email=${email}`;
      fetch(encodedURL, {
        method: "POST",
        body: JSON.stringify({ email: email }),
      })
        .then((response) => {
          response.text();
        })
        .then((data) => {
          if (data === undefined) {
            setLoading(false);
            setModalSuccess(true);
          } else {
            setLoading(false);
            setModalError(true);
          }
        });
    }
  };
  
  const goToIndex = () => {
    navigate("/");
  };

  return (
    <ImgBackPage>
      <Container>
        <Modal show={modalSuccess} onHide={goToLogin}>
          <Modal.Header closeButton>
            <Modal.Title>Estado</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Te hemo enviado un mail para cambiar la contraseña</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={goToLogin}>
              cerrar
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={modalError} onHide={goToLogin}>
          <Modal.Header closeButton>
            <Modal.Title>Estado</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Email no registrado</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={goToLogin}>
              cerrar
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="text-center p-5">
          <img src={logo} className="logo-login pointer" onClick={goToIndex} alt="logo"/>
        </div>
        <Form className="form z-depth-5" onSubmit={handleSubmit}>
          <h2 className="mb-5 text-center">Cambio de contraseña</h2>
          <div className="input-field col s6">
            <input
              type="email"
              id="email"
              name="email"
              onChange={handleChangeEmail}
            />
            <label htmlFor="userName">Email</label>
            <span className="helper-text red-text">{error}</span>
          </div>

          {loading ? (
            <div className="d-grid gap-2 p-4">
              <button
                size="lg"
                className="waves-effect waves-light btn text-white rounded-pill btn-LogIn"
              >
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </button>
            </div>
          ) : (
            <div className="d-grid gap-2 p-4">
              <button
                size="lg"
                type="submit"
                className="waves-effect waves-light btn text-white rounded-pill btn-LogIn"
              >
                Enviar
              </button>
            </div>
          )}
        </Form>
      </Container>
    </ImgBackPage>
  );
};

export default EmailChangePassword;
