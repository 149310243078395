import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { NotificationManager } from "react-notifications";

const initialBoolean = false;
const initialNumber = 0;
const initialArray = [];
const initialString = "";
const initialNull = null;
const initialConversation = [
  { message: "Bienvenido", userName: "Sistema", id: `${uuidv4()}` },
];

export const useAuction = (
  setColor,
  inAnAuction,
  setInAnAuction,
  setIdFinish,
  setIamOwner
) => {
  const [joined, setJoined] = useState(initialBoolean);
  const [price, setPrice] = useState(initialNumber);
  const [startingPrice, setStartingPrice] = useState(initialNumber);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(1);
  const [description, setDescription] = useState(initialString);
  const [actualBit, setActualBit] = useState(initialArray);
  const [conversation, setConversation] = useState(initialConversation);
  const [auction, setAuction] = useState(initialArray);
  const [free, setFree] = useState(initialArray);
  const [userName] = useState(localStorage.getItem("user"));
  const [connection, setConnection] = useState(initialNull);
  const [check_, setCheck_] = useState(null);
  const [check_2, setCheck_2] = useState(null);
  const [notificationFinish] = useState(null);


  const [stateConnection, setStateConnection] = useState([true, false]);
  const [ownerWithoutAuction, setOwnerWithoutAuction] = useState(false);
  const [iamWinner, setIamWinner] = useState({ state: false });
  const [itsPaidAuction, setItsPaidAuction] = useState(false);

  const handleCloseModal = () => setIamWinner({ state: false });
  const handleCloseModalOwnerWithoutAuction = () =>
    setOwnerWithoutAuction(false);

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(
        `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_AUCTIONS}`,
        {
          accessTokenFactory: () => localStorage.getItem("token"),
        }
      )
      .withAutomaticReconnect()
      .build();

    newConnection.on("NewUser", (message) => newUser(message));
    newConnection.on("NewUser1", (message) => newUser1(message));
    newConnection.on("NewUser2", (message) => newUser2(message));
    newConnection.on("NewUser3", (message) => newUser3(message));
    newConnection.on("LeftUser", (message) => leftUser(message));
    newConnection.on("startBid", (message) => startBid(message));
    newConnection.on("Bid", (message) => bid(message));
    newConnection.on("Check", (message) => check(message));
    newConnection.on("Check2", (message) => check2(message));
    newConnection.on("FinishBid", (message) => finishBid(message));
    newConnection.on("newFreeParking", (message) => newFreeParking(message));
    newConnection.on("getFreeParking", (message) => getFreeParking(message));
    newConnection.on("pay", (message) => pay(message));
    newConnection.on("paid", (message) => paid(message));
    newConnection.on("quitFromBid", (message) => quitFromBid(message));
    setConnection(newConnection);

    return () => {
      newConnection.stop();
    };
  }, []);

  //connect
  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((_) => {
          console.log("Connected!");
        })
        .catch((e) => console.log("Connection failed: ", e));
    }
  }, [connection]);
  
  //Join
  useEffect(() => {
    const join = async () => {
      if (stateConnection[1]) {
        connection.invoke("JoinGroup").then((_) => {});
        console.log("Joined");
        setJoined(true);
        setStateConnection([false, false]);
        NotificationManager.success("Bienvenido", "", 2000);
        console.clear();
      } else {
        setTimeout(join, 6000);
      }
    };
    setTimeout(join, 5000);
  }, [stateConnection]);

  //Join2
  useEffect(() => {
    if (stateConnection[0]) {
      if (connection) {
        setStateConnection([true, true]);
      } else {
        setStateConnection([true, false]);
      }
    }
  }, [connection]);

  const join = async () => {
    connection.invoke("JoinGroup").then((_) => {
      setJoined(true);
    });
  };

  const newUser = async (message) => {
   /*  console.log(message, "newUser"); */ // funciona si no hay subastas para traer
  };
  const newUser1 = async (message) => {
    /* console.log(message, "newUser1"); */ //te trae los estacionamiento gratuitos,
  };
  const newUser2 = async (message) => {
    /* console.log(message, "newUser2"); */ // te trae la cantidad de veces que el usuario se unió (para las publicidades)
  };
  const newUser3 = async (message) => {
    /* console.log(message); */
    let object = JSON.parse(message);
    let now = new Date().getTime(); //te trae el listado de subastas activas y las que no se pagaron por dos minutos
    setAuction(object);
    object.forEach((e) => {
      if (new Date(e.Time).getTime() < now) {
        if (e.Username === userName) {
          setIdFinish(localStorage.getItem("id-auction"));
          setIamOwner(true);
        } else {
          setIdFinish(localStorage.getItem("id-auction"));

          setIamWinner({ state: true, price: e.finalPrice, id: e.Id });
        }
        if (e.Users !== null && e.Users.length > 0) {
          e.Users.forEach((a) => {
            if (userName === a.userName) {
              localStorage.removeItem("id-auction");
              localStorage.setItem("id-auction", e.Id);
            }
          });
        }
      }
    });
  };

  const leave = async () => {
    /* connection.invoke("LeaveGroup").then((_) => setJoined(false)); */
  };

  const leftUser = async (message) => {
    /*  const conv = {
      userName: "Sistema",
      message: message,
      id: `${uuidv4()}`,
    };
    setConversation((oldArray) => [...oldArray, conv]); */
  };

  const sendBid = (id) => {
    /* ----------Delete-------------- */
    if (
      (inAnAuction[0] && !inAnAuction[2] && inAnAuction[1] === parseInt(id)) ||
      inAnAuction[1] === null
    ) {
      const message = {
        userName: `${userName}`,
        price: price,
        id: `${id}`,
      };
      connection.invoke("SendBid", message).then((_) => {});
    } else {
      const conv = {
        userName: "Sistema",
        message: "Ya se encuentra en una subasta",
        id: `${uuidv4()}`,
      };
      NotificationManager.warning("Ya se encuentra en una subasta", "", 2000);

      setConversation((oldArray) => [...oldArray, conv]);
    }
  };

  const bid = async (message) => {
    //Bidding { username = Tomas, price = 11, id = 58 }
    console.log("---------------bid---------------");
    console.log(message);
    console.log("---------------bid---------------");
    if (message !== "Wrong value." && message !== "Ended.") {
      let index = message.indexOf("id =");
      let id = message.substring(index + 4, message.length - 1);
      index = message.indexOf("price");
      let indexEnd = message.indexOf(", i");
      let price = message.substring(index + 8, indexEnd);
      index = message.indexOf("username");
      indexEnd = message.indexOf(", price");
      let user = message.substring(index + 10, indexEnd);

      const bit = {
        id: id,
        actualPrice: price,
        actualUser: user,
      };

      setActualBit((oldArray) => [...oldArray, bit]);

      const conv = {
        userName: user,
        price: price,
        id: `${uuidv4()}`,
      };

      NotificationManager.success(
        `${user} realizo una puja por ${price} $`,
        "",
        3000
      );
      setConversation((oldArray) => [...oldArray, conv]);

      if (user.trim() === userName) {
        localStorage.removeItem("id-auction");
        localStorage.setItem("id-auction", id);
      }

    } else {
      if (message !== "Ended.")
        NotificationManager.error(`Precio inferior al actual`, "", 2000);
      if (message !== "Wrong value.")
        NotificationManager.error(`Subasta terminada`, "", 2000);

      const conv = {
        userName: "Sistema",
        message: message,
        id: `${uuidv4()}`,
      };
      setConversation((oldArray) => [...oldArray, conv]);
    }
  };

  const startBidding = (e) => {
    /* ----------Delete-------------- */
    e.preventDefault();
    if (!inAnAuction[0]) {
      let daysInt;
      let hoursInt;
      let minutesInt;

      days === undefined || days === null || days === "" || days < 0
        ? (daysInt = 0)
        : (daysInt = parseInt(days));
      hours === undefined || hours === null || hours === "" || hours < 0
        ? (hoursInt = 0)
        : (hoursInt = parseInt(hours));
      minutes === undefined || minutes === null || minutes === "" || minutes < 0
        ? (minutesInt = 0)
        : (minutesInt = parseInt(minutes));

      let minutesSend = daysInt * 1440 + hoursInt * 60 + minutesInt;

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((p) => {
          const message = {
            price: startingPrice,
            minutes: `${minutesSend}`,
            description: description,
            parklong: p ? `${p.coords.longitude}` : "1",
            parklat: p ? `${p.coords.latitude}` : "1",
          };
          connection.invoke("startBidding", message).then((_) => {});
          setInAnAuction([true, null, true]);
        });
      }
    } else {
      const conv = {
        userName: "Sistema",
        message: "Ya se encuentra en una subasta",
        id: `${uuidv4()}`,
      };
      NotificationManager.warning("Ya se encuentra en una subasta", "", 2000);

      setConversation((oldArray) => [...oldArray, conv]);
    }
  };

  const startBid = async (message) => {
    //id:1, price:1 , minutes:1 , time:31/1/2023 10:36:22 , description :1, owner: Tomas, vehicletype: Motorbike, model: , brand: , plate:
    console.log("---------------startBid---------------");
    console.log(message);
    console.log("---------------startBid---------------");
    let index = message.indexOf("description :");
    let indexEnd = message.indexOf(", o");
    let description = message.substring(index + 13, indexEnd);
    index = message.indexOf("minutes:");
    indexEnd = message.indexOf(" , t");
    let minutes = message.substring(index + 8, indexEnd);
    index = message.indexOf("price:");
    indexEnd = message.indexOf(", m");
    let price = message.substring(index + 6, indexEnd);
    index = message.indexOf("id:");
    indexEnd = message.indexOf(", p");
    let id = message.substring(index + 3, indexEnd);
    index = message.indexOf("owner:");
    indexEnd = message.indexOf(", v");
    let owner = message.substring(index + 7, indexEnd);
    index = message.indexOf("owner:");
    indexEnd = message.indexOf(", mo");
    let vehicletype = message.substring(index + 13, indexEnd);
    index = message.indexOf("model: ");
    indexEnd = message.indexOf(", b");
    let model = message.substring(index + 13, indexEnd);
    index = message.indexOf("brand: ");
    indexEnd = message.indexOf(", pl");
    let brand = message.substring(index + 13, indexEnd);
    index = message.indexOf("plate:");
    let plate = message.substring(index + 13, message.length);

    const conv = {
      minutes: minutes,
      price: price,
      description: description,
      Username: owner,
      vehicletype: vehicletype,
      id: `${uuidv4()}`,
    };
    const newAuction = {
      Id: id,
      price: price,
      date: new Date(),
      Username: owner,
      minutes: minutes,
      vehicletype: vehicletype,
      description: description,
      model: model,
      brand: brand,
      plate: plate,
    };

    setAuction((oldArray) => [...oldArray, newAuction]);

    NotificationManager.success(
      `${owner} creo una subasta: ${minutes} minutos - ${price}$`,
      "",
      3000
    );
    setConversation((oldArray) => [...oldArray, conv]);

    if (owner === userName) {
      localStorage.removeItem("id-auction");
      localStorage.setItem("id-auction", id);
    }
  };

  const checkForBiddings = async () => {
    /* connection.invoke("checkForBiddings").then((_) => {}); */
  };

  const check = async (message) => {
    console.log("---------------check---------------");
    console.log(message);
    console.log("---------------check---------------");

    let array = JSON.parse(message);
    setCheck_(array);
    if (message[3] === "I") {
      let array = JSON.parse(message);
      setCheck_(array);
    }
    if (message[3] === "i") {
      let array = JSON.parse(message);
      setFree(array);
    }
  };

  const checkForBiddings2 = async () => {
    /* connection.invoke("checkForBiddings").then((_) => {}); */
  };

  const check2 = async (message) => {
    let array = JSON.parse(message);
    let winners = [];
    let owners = [];
    let inAnAuction = [];

    if (array) {
      if (array.length > 0) {
        array.map((e) => {
          winners.push(e.winnerUserName);
          owners.push(e.Username);

          e.userHistorials?.map((h) => {
            if (
              h.userName !== array.Username &&
              h.userName !== array.winnerUserName
            ) {
              inAnAuction.push(h.userName);
            }
          });
        });
      } else {
        winners = array.winnerUserName;
        owners = array.Username;
        array.userHistorials?.map((h) => {
          if (
            h.userName !== array.Username &&
            h.userName !== array.winnerUserName
          ) {
            inAnAuction.push(h.userName);
          }
        });
      }
    }
    setCheck_2({ winners, owners, inAnAuction });
  };

  const finish_bid = async (id, early) => {
    /*  if (early)
      NotificationManager.warning("has cancelado la subasta", "", 2000);

    connection.invoke("finishBid", id, early).then((_) => {}); */
  };

  const finishBid = async (message) => {
    //'id':'$25','winner':'test','price':'2'
    console.log("---------------finishBid---------------");
    console.log(message);
    console.log("---------------finishBid---------------");
    let index = message.indexOf("'id'");
    let indexEnd = message.indexOf("','w");
    let id = message.substring(index + 7, indexEnd);
    index = message.indexOf("r':'");
    indexEnd = message.indexOf("','p");
    let winner = message.substring(index + 4, indexEnd);
    index = message.indexOf("e':'");
    let price = message.substring(index + 4, message.length - 1);

    setItsPaidAuction(false);

    if (message === "No winner.") {
      setOwnerWithoutAuction(true);
    } else if (winner === userName) {
      setIamWinner({ state: true, price: price, id: id });
    }
  };

  const freeParking = async () => {
    /*  if (!inAnAuction[2]) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((p) => {
          connection
            .invoke(
              "freeParking",
              p ? `${p.coords.longitude}` : "1",
              p ? `${p.coords.latitude}` : "1"
            )
            .then((_) => {});
        });
      }
    } */
  };

  const newFreeParking = async (message) => {
    console.log("---------------newFreeParking---------------");
    console.log(message);
    console.log("---------------newFreeParking---------------");
    //'id':'14','type':'freeparking','longitude' : '-58.67', 'latitude': '-34.75','vehicletype':'Motorbike'

    /*  let index = message.indexOf("'id':'");
    let indexEnd = message.indexOf("','t");
    let id = message.substring(index + 6, indexEnd);
    index = message.indexOf("minutes:");
    indexEnd = message.indexOf(", d");
    let type = message.substring(index + 8, indexEnd);
    index = message.indexOf("price:");
    indexEnd = message.indexOf(", m");
    let longitude = message.substring(index + 6, indexEnd);
    index = message.indexOf("id:");
    indexEnd = message.indexOf(", p");
    let latitude = message.substring(index + 3, indexEnd);
    index = message.indexOf("owner:");
    let vehicletype = message.substring(index + 13, message.length);

    const parking = {
      id: id,
      type: type,
      longitude: longitude,
      latitude: latitude,
      vehicletype: vehicletype,
      id: `${uuidv4()}`,
    };

    setFree((oldArray) => [...oldArray, parking]); */
  };

  const getFreeParking_ = async (id) => {
    /*  connection.invoke("getFreeParking", `${id}`).then((_) => {}); */
  };

  const getFreeParking = async (message) => {
    console.log("---------------getFreeParking---------------");
    console.log(message);
    console.log("---------------getFreeParking---------------");
    //'id':'76','type':'freeparking','active' : 'false'
  };

  const payCheck = async (id, winnerLat, winnerLong) => {
    /*   connection
      .invoke("payCheck", `${id}`, `${winnerLong}`, `${winnerLat}`)
      .then((_) => {}); */
  };

  const pay = async (message) => {
    /*  let index = message.indexOf(".");
    let msg = message.substring(0, index);

    setNotificationFinish(msg); */
  };

  const itsPaid = async (id) => {
    /*  connection.invoke("paid", `${id}`).then((_) => {}); */
  };

  const paid = async (message) => {
    /*  if (message === "Pay done.") {
      setItsPaidAuction(true);
      NotificationManager.success("Pago completado", "", 59000);

      localStorage.removedItem("id-auction")
        setIdFinish(null)
        setIamOwner(false)
        setIamWinner({ state: false});
    } */
  };

  const quitFromBid_ = async (id, userName) => {
    localStorage.removeItem("id-auction");
    connection.invoke("quitFromBid", `${id}`, `${userName}`).then((_) => {});
  };

  const quitFromBid = async (message) => {
    //'id':'22','user':'test','action':'disconnect'
    let index = message.indexOf("'id'");
    let indexEnd = message.indexOf("','u");
    let id = message.substring(index + 7, indexEnd);
    index = message.indexOf("r':'");
    indexEnd = message.indexOf("','a");
    let user = message.substring(index + 4, indexEnd);

    NotificationManager.warning(`${user} dejo la subasta ${id}`, "", 3000);
  };

  const finishAuction = (id, early) => {
    /* if (auction.length > 1) {
      setAuction(auction.filter((e) => e.Id !== id));
    }
    if (auction.length <= 1) {
      setAuction([]);
    }

    finish_bid(`${id}`, early); */
  };

  const takeFree = (id) => {
    /* if (free.length > 1) {
      setFree(free.filter((e) => e.id !== id));
    }
    if (free.length <= 1) {
      setFree([]);
    }
    getFreeParking_(id); */
  };

  return {
    joined,
    setPrice,
    setStartingPrice,
    setDays,
    setHours,
    setMinutes,
    setDescription,
    actualBit,
    auction,
    conversation,
    checkForBiddings,
    checkForBiddings2,
    finish_bid,
    connection,
    check_,
    check_2,
    stateConnection,
    iamWinner,
    handleCloseModal,
    leave,
    sendBid,
    startBidding,
    join,
    freeParking,
    free,
    setAuction,
    getFreeParking_,
    takeFree,
    finishAuction,
    payCheck,
    notificationFinish,
    itsPaid,
    ownerWithoutAuction,
    handleCloseModalOwnerWithoutAuction,
    setItsPaidAuction,
    itsPaidAuction,
    quitFromBid_,
  };
};
