import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button, Container, Modal } from "react-bootstrap";
import "./FormRL.css";
import Spinner from "react-bootstrap/Spinner";
import imgVehicletype from "../../assets/img/vehicleType/ImgVehicletype";
import { helpHttp } from "../../helpers/helpHttp";
import logo from "../../assets/img/new/Posta.png";
import { useNavigate } from "react-router-dom";
import SessionContext from "../../context/SessionContext";
import { ImgBackPage } from "../../util/constant/constant";

const getUrl = () => {
  return window.location.pathname;
};

const FormRL = () => {
  const { setToken, setUser } = useContext(SessionContext);
  const [loading, setLoading] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  let navigate = useNavigate();

  const [models, setModels] = useState([]);
  const [model, setModel] = useState("");
  const [errorModel] = useState(" ");
  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState("");
  const [errorBrand, setErrorBrand] = useState(" ");

  const [userName, setUserName] = useState("");
  const [errorUserName, setErrorUserName] = useState(" ");
  const [mail, setMail] = useState("");
  const [errorMail, setErrorMail] = useState(" ");
  const [password, setPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState(" ");
  const [rePassword,setRePassword] = useState("");
  const [errorRePassword, setErrorRePassword] = useState(" ");
  const [plate, setPlate] = useState("");
  const [errorPlate, setErrorPlate] = useState(" ");
  const [vehicleType, setVehicleType] = useState(0);

  const send = (url) => {
    let api = helpHttp();

    let formSend = {};

    if (getUrl() === `${process.env.REACT_APP_REGISTER_FRONT}`) {
      formSend = {
        userName: userName,
        email: mail,
        password: password,
        plate: plate,
        brand: brand,
        model: model,
        vehicleType: vehicleType,
        deviceId:"1"
      };
    } else {
      formSend = {
        userName: userName,
        password: password,
        deviceId: "1",
      };
    }

    let options = {
      body: formSend,
      headers: { "Content-Type": "application/json" },
    };

    api.post(url, options).then((res) => {
      if (res !== "Register" && res.token === undefined) {
        setLoading(false);
        setModalError(true);
        return;
      }else{
        setToken(res.token);
        localStorage.setItem("token", res.token);
      }

      setUser(userName);
      localStorage.setItem("user", userName);
      setLoading(false);
      setModalSuccess(true);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (getUrl() === `${process.env.REACT_APP_REGISTER_FRONT}`) {
      if (
        errorPlate === "" &&
        errorRePassword === "" &&
        errorPassword === "" &&
        errorMail === "" &&
        errorUserName === "" &&
        errorBrand === ""
      ) {
        setLoading(true);
        let urlRegister = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_REGISTER}`;
        send(urlRegister);
      }
    } else {
      if (errorPassword === "" && errorUserName === "") {
        setLoading(true);
        let urlLogin = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_LOGIN}`;
        send(urlLogin);
      }
    }
  };

  useEffect(() => {
    const URL_GET_BRAND = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_GET_MAKE}`;
    let options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    helpHttp()
      .get(URL_GET_BRAND, options)
      .then((res) => {
        if (Array.isArray(res)) setBrands(res);
      });
  }, []);

  const selectType = (e) => {
    let id = e.currentTarget.getAttribute("id");
    let select = document.getElementById(id);

    const types = document.querySelectorAll(".select-type");

    types.forEach((shirt) => {
      shirt.classList.remove("select-type");
    });

    select.classList.toggle("select-type");

    setVehicleType(parseInt(id));
  };

  const handleChangeBrand = (e) => {
    let car = brands[e.target.value];
    setBrand(car);
    setErrorBrand("");
    const REACT_APP_MODE = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_GET_MODE}${car}`;
    let options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    helpHttp()
      .get(REACT_APP_MODE, options)
      .then((res) => {
        if (!res.err) {
          setModels(res);
          setModel(res[0]);
        }
      });
  };

  const handleChangeModel = (e) => {
    let model = e.target.value;
    setModel(model);
  };

  const handleChangeUserName = (e) => {
    let regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;

    let value = e.target.value;
    setUserName(value);

    if (!value.trim()) {
      setErrorUserName("El campo Nombre de usuario es requerido");
    } else if (!regexName.test(value.trim())) {
      setErrorUserName(
        "El campo nombre de usuario solo acepta letras mayusculas y minusculas"
      );
    } else {
      setErrorUserName("");
    }
  };

  const handleChangeEmail = (e) => {
    let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}([/.]\w{2,})?$/;

    let value = e.target.value;
    setMail(value);

    if (!value.trim()) {
      setErrorMail("El campo mail es requerido");
    } else if (!regexEmail.test(value.trim())) {
      setErrorMail("Formato incorrecto, ej: ejemplo@gmail.com");
    } else {
      setErrorMail("");
    }
  };

  const handleChangePlate = (e) => {
    let regexPlateOld = /^[a-z]{3}[\d]{3}$/;
    let regexPlateNew = /^[a-z]{2}[\d]{3}[a-z]{2}$/;

    let value = e.target.value;
    setPlate(value);

    if (!value.trim()) {
      setErrorPlate("El campo patente es requerido");
    } else if (
      !regexPlateOld.test(value.trim()) &&
      !regexPlateNew.test(value.trim())
    ) {
      setErrorPlate("Formato incorrecto, ej: aaa000 o aa000aa");
    } else {
      setErrorPlate("");
    }
  };

  const handleChangePassword = (e) => {
    let regexPassword =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?._!@$%^&*-]).{6,}$/;

    let value = e.target.value;
    setPassword(value);

    if (!value.trim()) {
      setErrorPassword("El campo contraseña es requerido");
    } else if (!regexPassword.test(value.trim())) {
      setErrorPassword(
        "El campo contraseña debe tener al menos 6 caracteres, una mayuscula, una minuscula, un numero y un caracter especial"
      );
    } else {
      setErrorPassword("");
    }
  };

  const handleChangeRePassword = (e) => {
    let value = e.target.value;
    setRePassword(value);

    if (!value.trim()) {
      setErrorRePassword("El campo Contraseña es requerido");
    } else if (value !== password) {
      setErrorRePassword("Las Contraseñas no coinciden");
    } else {
      setErrorRePassword("");
    }
  };

  const goToLogin = () => {
    navigate(`${process.env.REACT_APP_LOGIN_FRONT}`);
  };
  const goToRegister = () => {
    navigate(`${process.env.REACT_APP_REGISTER_FRONT}`);
  };
  const goToChangePassword = () => {
    navigate(`${process.env.REACT_APP_CHANGE_PASSWORD_FRONT}`);
  };
  const goToIndex = () => {
    navigate("/");
  };

  const closeModalSuccess = () => {
    if (getUrl() === `${process.env.REACT_APP_REGISTER_FRONT}`) {
      setModalSuccess(false);
      goToLogin();
    } else {
      setModalSuccess(false);
      navigate("/mapa");
    }
  };
  const closeModalError = () => {
    if (getUrl() === `${process.env.REACT_APP_REGISTER_FRONT}`) {
      setModalError(false);
      goToRegister();
    } else {
      setModalError(false);
      goToLogin();
    }
  };

  return (
    <ImgBackPage>
      <Container>
        <div className="text-center">
          <img src={logo} className="logo-login pointer" onClick={goToIndex} alt="logo"/>
        </div>
        <h1 className="mb-5 text-white text-center mt-3 font-weight-bold">
          {getUrl() === `${process.env.REACT_APP_REGISTER_FRONT}`
            ? "Registrarse"
            : "Iniciar sesion"}
        </h1>
        <Form className="form z-depth-5" onSubmit={handleSubmit}>
          {getUrl() === `${process.env.REACT_APP_REGISTER_FRONT}` ? (
            <div className="switch d-flex" onClick={goToLogin}>
              <div className="login">
                <p className="text-center text-switch">Ingresar</p>
              </div>
              <div className="register-active">
                <p className="text-center text-switch">Registrarse</p>
              </div>
            </div>
          ) : (
            <div className="switch d-flex" onClick={goToRegister}>
              <div className="login-active">
                <p className="text-center text-switch">Ingresar</p>
              </div>
              <div className="register">
                <p className="text-center text-switch">Registrarse</p>
              </div>
            </div>
          )}

          <h1 className="mb-5 mt-4 text-center">
            {getUrl() === `${process.env.REACT_APP_REGISTER_FRONT}`
              ? ""
              : "¡Bienvenido!"}
          </h1>

          <div className="input-field col s6">
            <input
              id="userName"
              type="text"
              onChange={handleChangeUserName}
              autoFocus
            />
            <label htmlFor="userName">Nombre de usuario</label>
            <span className="helper-text red-text">{errorUserName}</span>
          </div>

          {getUrl() === `${process.env.REACT_APP_REGISTER_FRONT}` && (
            <div className="input-field col s6">
              <input
                type="email"
                id="email"
                name="email"
                onChange={handleChangeEmail}
              />
              <label htmlFor="userName">Email</label>
              <span className="helper-text red-text">{errorMail}</span>
            </div>
          )}

          {getUrl() === `${process.env.REACT_APP_REGISTER_FRONT}` && (
            <div className="input-field col s6">
              <input
                id="plate"
                type="text"
                name="plate"
                onChange={handleChangePlate}
              />
              <label htmlFor="plate">Patente</label>
              <span className="helper-text red-text">{errorPlate}</span>
            </div>
          )}

          <div className="input-field col s6">
            <input
              id="password"
              type="password"
              name="password"
              onChange={handleChangePassword}
            />
            <label htmlFor="password">Contraseña</label>
            <span className="helper-text red-text">{errorPassword}</span>
          </div>

          {getUrl() === `${process.env.REACT_APP_REGISTER_FRONT}` && (
            <div className="input-field col s6">
              <input
                type="password"
                name="rePassword"
                onChange={handleChangeRePassword}
              />
              <label htmlFor="rePassword">Contraseña</label>
              <span className="helper-text red-text">{errorRePassword}</span>
            </div>
          )}
          {getUrl() === `${process.env.REACT_APP_REGISTER_FRONT}` && (
            <Form.Group className="mb-3">
              <Form.Label className="ps-3">Marca</Form.Label>
              <Form.Select aria-label="brand" onChange={handleChangeBrand}>
                {brand === "" && (
                  <option key={0} value={0}>
                    Elije una marca de auto
                  </option>
                )}
                {brands.sort().map((m, i) => (
                  <option key={i} value={i}>
                    {m}
                  </option>
                ))}
              </Form.Select>
              <span className="helper-text red-text">{errorBrand}</span>
            </Form.Group>
          )}

          {getUrl() === `${process.env.REACT_APP_REGISTER_FRONT}` &&
            models.length > 0 && (
              <Form.Group className="mb-3">
                <Form.Label className="ps-3">Modelo</Form.Label>
                <Form.Select aria-label="model" onChange={handleChangeModel}>
                  {models.map((m, i) => (
                    <option key={i} value={m}>
                      {m}
                    </option>
                  ))}
                </Form.Select>
                <span className="helper-text red-text">{errorModel}</span>
              </Form.Group>
            )}

          {getUrl() === `${process.env.REACT_APP_REGISTER_FRONT}` && (
            <Form.Group className="mb-3">
              <Form.Label>Tipo de vehiculo</Form.Label>
              <div className="mb-3 d-flex">
                <div
                  key={imgVehicletype[0].nro}
                  id={imgVehicletype[0].nro}
                  className="img-type m-2 select-type"
                  onClick={selectType}
                >
                  <img
                    src={imgVehicletype[0].img}
                    className="img-thumbnail rounded"
                    alt={imgVehicletype[0].title}
                  />
                </div>
                {imgVehicletype.map((type) =>
                  type.id !== 101 ? (
                    <div
                      key={type.nro}
                      id={type.nro}
                      className="img-type m-2"
                      onClick={selectType}
                    >
                      <img
                        src={type.img}
                        className="img-thumbnail rounded"
                        alt={type.title}
                      />
                    </div>
                  ) : null
                )}
              </div>
            </Form.Group>
          )}

          {loading ? (
            <div className="d-grid gap-2 p-4">
              <button
                size="lg"
                className="waves-effect waves-light btn text-white rounded-pill btn-LogIn"
              >
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </button>
            </div>
          ) : (
            <div className="d-grid gap-2 p-4">
              <button
                size="lg"
                type="submit"
                className="waves-effect waves-light btn text-white rounded-pill btn-LogIn"
              >
                Ingresar
              </button>
            </div>
          )}
          {getUrl() === `${process.env.REACT_APP_LOGIN_FRONT}` && (
            <h4
              className="text-change-password text-center mt-2 pb-3"
              onClick={goToChangePassword}
            >
              ¿Olvidaste tu contraseña?
            </h4>
          )}

          <Modal
            show={modalSuccess}
            onHide={closeModalSuccess}
            className="modal-success"
          >
            <Modal.Header closeButton>
              <Modal.Title>Estado</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {getUrl() === `${process.env.REACT_APP_REGISTER_FRONT}` ? (
                <>
                  <b>Registro Exitoso !</b>
                  <p>Te enviamos un mail para confirmar la cuenta</p>
                </>
              ) : (
                <>
                  <b>Login Exitoso !</b>
                </>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeModalSuccess}>
                cerrar
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={modalError}
            onHide={closeModalError}
            className="modal-error"
          >
            <Modal.Header closeButton>
              <Modal.Title>Estado</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {getUrl() === `${process.env.REACT_APP_REGISTER_FRONT}` ? (
                <>
                  {" "}
                  Error en el registro <br></br> Usuario o email en uso{" "}
                </>
              ) : (
                "Error en el login !"
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeModalError}>
                cerrar
              </Button>
            </Modal.Footer>
          </Modal>
        </Form>
      </Container>
      <div className="mt-5">&nbsp;</div>
    </ImgBackPage>
  );
};

export default FormRL;
