import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Loader from "../../common/loader/Loader";

const ModalMP=({ modalMp, setModalMp, loaderMp, mpItem,formatDateTime })=> {
  return (
    <Modal show={modalMp} onHide={() => setModalMp(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-3">
            Informacion de la Subasta en Mercado Pago
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-loader-search-bidId">
            {loaderMp && <Loader />}
            {mpItem &&
              (mpItem instanceof String ? (
                <div>{mpItem}</div>
              ) : (
                <div className="fs-5">
                  <div>
                    <span className="font-weight-bold">Subasta: </span>
                    {mpItem.additional_info.items[0].title}
                  </div>
                  <div>
                    <span className="font-weight-bold">Precio: </span>
                    {mpItem.additional_info.items[0].unit_price}
                  </div>
                  <div>
                    <span className="font-weight-bold">Moneda: </span>
                    {mpItem.currency_id}
                  </div>
                  <div>
                    <span className="font-weight-bold">Fee: </span>
                    {mpItem.fee_details[0].amount}
                  </div>
                  <div>
                    <span className="font-weight-bold">Estado: </span>
                    {mpItem.status}
                  </div>
                  <div>
                    <span className="font-weight-bold">
                      Fecha de creacion:{" "}
                    </span>
                    {formatDateTime(mpItem.charges_details[0].date_created)}
                  </div>
                </div>
              ))}
            {!mpItem && <p>No se encontro la subasta en Mercado pago</p>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalMp(false)}>
            cerrar
          </Button>
        </Modal.Footer>
      </Modal>
  )
}

export default ModalMP