import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button, Container } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { useSearchParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import background from "../../assets/img/new/half-fondo.png";
import logo from "../../assets/img/new/Posta.png";

const imgBack = {
  backgroundImage: `url(${background})`,
  backgroundPosition: "bottom",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  height: "70vh",
  position: "relative",
};
const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [error, setError] = useState("");
  const [loading] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();

  const handleSubmit = () => {
    let userId = searchParams.get("userId");
    let token = encodeURIComponent(
      encodeURIComponent(searchParams.get("token"))
    );
    if (error === "") {
      let encodedURL = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_CHANGE_PASSWORD}?userId=${userId}&token=${token}&password=${password}`;
      fetch(encodedURL, {
        method: "POST",
        body: JSON.stringify({
          userId: userId,
          token: token,
          password: password,
        }),
      })
        .then((response) => {
          response.text();
        })
        .then((data) => {
          console.log(data);
          if (data === undefined) {
            setShowModel(true);
          }
        });
    }
  };

  const goToMap = () => {
    setShowModel(false);
    navigate(`${process.env.REACT_APP_CONFIRM_PASSWORD}`);
  };

  useEffect(() => {
    let regexPassword =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?._!@$%^&*-]).{6,}$/;
    if (!password.trim()) {
      setError(
        "El campo contraseña debe tener al menos 6 caracteres, una mayuscula, una minuscula, un numero y un caracter especial"
      );
    } else {
      if (!regexPassword.test(password.trim())) {
        setError(
          "El campo contraseña debe tener al menos 6 caracteres, una mayuscula, una minuscula, un numero y un caracter especial"
        );
      } else {
        if (password !== rePassword) {
          setError("Las contraseñas no coinciden");
        } else {
          setError("");
        }
      }
    }
  }, [password, rePassword]);

  return (
    <div style={imgBack}>
      <Container>
        <Modal show={showModel} onHide={goToMap}>
          <Modal.Header closeButton>
            <Modal.Title>Cambio de contraseña</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Cambio correcto</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={goToMap}>
              cerrar
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="text-center pb-4">
          <img src={logo} className="logo-login" alt="logo"/>
        </div>

        <Form className="form z-depth-5">
          <h2 className="mb-5 text-center">Cambio de contraseña</h2>

          <div className="input-field col s6">
            <input
              type="password"
              name="password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <label htmlFor="userName">Contraseña</label>
          </div>

          <div className="input-field col s6">
            <input
              type="password"
              name="rePassword"
              id="rePassword"
              onChange={(e) => setRePassword(e.target.value)}
              required
            />
            <label htmlFor="userName">Repetir contraseña</label>
            <span className="helper-text red-text">{error}</span>
          </div>

          {loading ? (
            <div className="d-grid gap-2 p-4">
              <button
                size="lg"
                className="waves-effect waves-light btn text-white rounded-pill btn-LogIn"
              >
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </button>
            </div>
          ) : (
            <div className="d-grid gap-2 p-4">
              <Button
                size="lg"
                className="waves-effect waves-light btn text-white rounded-pill btn-LogIn"
                onClick={handleSubmit}
              >
                Enviar
              </Button>
            </div>
          )}
        </Form>
      </Container>
    </div>
  );
};

export default ChangePassword;
