import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/new/Posta.png";
import { ImgBackPage } from "../../util/constant/constant";

const ValidationPassword = () => {
  let navigate = useNavigate();

  const goMap = () => {
    navigate(`${process.env.REACT_APP_MAP_FRONT}`);
  };

  return (
    <ImgBackPage>
        <Container>
          <div className="text-center p-5">
            <img src={logo} className="logo-login pointer" onClick={goMap} alt="logo"/>
          </div>
          <Form className="form z-depth-5">
            <h1 className="mb-5">Contraseña cambiada correctamente</h1>
            <p>La contraseña fue cambiada correctamente</p>
          </Form>
        </Container>
    </ImgBackPage>
  );
};

export default ValidationPassword;
