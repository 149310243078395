import Offcanvas from "react-bootstrap/Offcanvas";
import "./Profile.css";
import Form from "react-bootstrap/Form";
import { Accordion, Spinner } from "react-bootstrap";
import { useContext, useState } from "react";
import { NotificationManager } from "react-notifications";
import logo from "../../assets/img/new/Posta.png";
import SessionContext from "../../context/SessionContext";
import { useNavigate } from "react-router-dom";

const Profile = ({ show, handleClose, startAuction, setShowHistory }) => {
  const [errorMail, setErrorMail] = useState(
    "ingrese el mail para cambiar la contraseña"
  );
  const [mail, setMail] = useState("");
  const [loading, setLoading] = useState(false);
  const { logout } = useContext(SessionContext);
  let navigate = useNavigate();

  const handleSubmitMail = (e) => {
    e.preventDefault();
    if (errorMail === "") {
      setLoading(true);
      let encodedURL = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_SEND_PASSWORD}?email=${mail}`;
      fetch(encodedURL, {
        method: "POST",
        body: JSON.stringify({ email: mail }),
      })
        .then((response) => {
          response.text();
        })
        .then((data) => {
          if (data === undefined) {
            NotificationManager.success(
              `Hemos enviado un mail a ${mail}`,
              "",
              4000
            );
            setLoading(false);
          }
        });
    }
  };

  const validationsMail = (e) => {
    let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}([/.]\w{2,})?$/;

    let value = e.target.value;
    setMail(value);

    if (!value.trim()) {
      setErrorMail("El campo mail es requerido");
    } else if (!regexEmail.test(value.trim())) {
      setErrorMail("Formato incorrecto, ej: ejemplo@gmail.com");
    } else {
      setErrorMail("");
    }
  };

  const closeCanvasStartAuction = () => {
    startAuction();
    handleClose();
  };

  const handlerHistory = () => {
    setShowHistory(true);
  };

  const getUrl = () => {
    return window.location.pathname;
  };

  const goToAdminOrMap = () => {
    if (window.location.pathname === process.env.REACT_APP_MAP_FRONT) {
      navigate(`${process.env.REACT_APP_ADMIN_FRONT}`);
    } else {
      navigate(`${process.env.REACT_APP_MAP_FRONT}`);
    }
  };

  return (
    <Offcanvas show={show} onHide={handleClose} className="off-canvas">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title></Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="text-center">
          <img src={logo} className="logo-login pointer mb-5" alt="logo" />
        </div>

        <div className="d-grid mb-5">
          <button
            size="lg"
            className="waves-effect waves-light btn text-white rounded-pill btn-LogIn"
            onClick={goToAdminOrMap}
          >
            {window.location.pathname === process.env.REACT_APP_MAP_FRONT
              ? "Admin"
              : "Mapa"}
          </button>
        </div>

        {window.location.pathname === process.env.REACT_APP_MAP_FRONT ? (
          <div className="d-grid mb-5">
            <button
              size="lg"
              className="waves-effect waves-light btn text-white rounded-pill btn-LogIn"
              onClick={handlerHistory}
            >
              Historial de subastas
            </button>
          </div>
        ) : null}

        <div className="d-grid mb-5">
          <button
            size="lg"
            className="waves-effect waves-light btn text-white rounded-pill btn-LogIn"
            onClick={logout}
          >
            Cerrar sesion
          </button>
        </div>

        <Accordion>
          <Accordion.Item eventKey="0" className="accordion">
            <Accordion.Header className="accordion">
              Cambiar contraseña
            </Accordion.Header>
            <Accordion.Body>
              <Form className="mt-4 mb-5" onSubmit={handleSubmitMail}>
                <div className="input-field col s6">
                  <input
                    type="email"
                    className="white-text"
                    id="email"
                    name="email"
                    onChange={validationsMail}
                  />
                  <label htmlFor="userName" className="white-text">
                    Email
                  </label>
                  {errorMail && (
                    <span className="helper-text red-text black">
                      {errorMail}
                    </span>
                  )}
                </div>

                {loading ? (
                  <div className="d-grid gap-2">
                    <button
                      size="lg"
                      type="submit"
                      className="waves-effect waves-light btn text-white rounded-pill btn-LogIn"
                      disabled
                    >
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </button>
                  </div>
                ) : (
                  <div className="d-grid gap-2">
                    <button
                      size="lg"
                      type="submit"
                      className="waves-effect waves-light btn text-white rounded-pill btn-LogIn"
                    >
                      Enviar
                    </button>
                  </div>
                )}
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default Profile;
