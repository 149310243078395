import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  ButtonGroup,
  Form,
  Pagination,
  Table,
  ToggleButton,
} from "react-bootstrap";
import { helpHttp } from "../../helpers/helpHttp";
import Loader from "../../common/loader/Loader";
import { v4 as uuidv4 } from "uuid";

const OPTION_STATE = {
  approved: "7",
  pending: "1",
  in_process: "2",
  rejected: "3",
  cancelled: "4",
  refunded: "5",
  charged_back: "6",
};

const OPTION_BUTTONS_STATE = [
  { name: "Aprobado", value: OPTION_STATE.approved },
  { name: "Pendiente", value: OPTION_STATE.pending },
  { name: "En proceso", value: OPTION_STATE.in_process },
  { name: "Rechazado", value: OPTION_STATE.rejected },
  { name: "Cancelado", value: OPTION_STATE.cancelled },
  { name: "Reembolsado", value: OPTION_STATE.refunded },
  { name: "Contracargo", value: OPTION_STATE.charged_back },
];

const OPTION_FROM_TIME = {
  oneMonth: "1",
  treehMonth: "3",
  oneDay: "1d",
  sevenDay: "7d",
};

const OPTION_BUTTONS_FROM_TIME = [
  { name: "1 mes", value: OPTION_FROM_TIME.oneMonth },
  { name: "3 meses", value: OPTION_FROM_TIME.treehMonth },
  { name: "1 dia", value: OPTION_FROM_TIME.oneDay },
  { name: "7 dias", value: OPTION_FROM_TIME.sevenDay },
];

const MAX_FIELD = 30;

export default function Mp() {
  const [mp, setMp] = useState(null);
  const [mpFilter, setMpFilter] = useState(null);
  const [loaderSearch, setLoaderSearch] = useState(true);
  const [search, setSearch] = useState("");
  const [fromTime, setFromTime] = useState("1MONTH");
  const [status, setStatus] = useState("approved");
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(30);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    const newOffset = (pageNumber - 1) * perPage;
    setOffset(newOffset.toString());
  };

  const totalPages = Math.ceil(total / perPage);

  let items = [];
  for (let number = 1; number <= totalPages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => handlePageChange(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  useEffect(() => {
    setLoaderSearch(true);
    const newOffset = (currentPage - 1) * perPage;
    setOffset(newOffset.toString());
    const URL_MP = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_MP_LIST}?fromTime=${fromTime}&status=${status}&limit=${MAX_FIELD}&offset=${offset}`;

    let options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    helpHttp()
      .get(URL_MP, options)
      .then((res) => {
        let array = JSON.parse(res);
        setMp(array.results);
        setTotal(array.paging.total);
        setLoaderSearch(false);
      });
  }, [currentPage, perPage, fromTime, status, offset]);

  const sendSearch = (e) => {
    e.preventDefault();
    if (search === "") {
      setMpFilter(null);
      return;
    }
    const auctionFilter = mp.find(
      (auction) =>
        auction.additional_info.items[0].title === `Subasta ${search}`
    );

    if (auctionFilter === undefined) {
      setMpFilter(null);
    } else {
      setMpFilter(auctionFilter);
    }
  };

  const handlerState = (valueFilter) => {
    setCurrentPage(1)
    if (valueFilter === OPTION_STATE.approved) setStatus("approved");
    if (valueFilter === OPTION_STATE.pending) setStatus("pending");
    if (valueFilter === OPTION_STATE.in_process) setStatus("in_process");
    if (valueFilter === OPTION_STATE.rejected) setStatus("rejected");
    if (valueFilter === OPTION_STATE.cancelled) setStatus("cancelled");
    if (valueFilter === OPTION_STATE.refunded) setStatus("refunded");
    if (valueFilter === OPTION_STATE.charged_back) setStatus("charged_back");
  };

  const handlerFromTime = (valueFilter) => {
    setCurrentPage(1)
    if (valueFilter === OPTION_FROM_TIME.oneMonth) setFromTime("1MONTH");
    if (valueFilter === OPTION_FROM_TIME.treehMonth) setFromTime("3MONTH");
    if (valueFilter === OPTION_FROM_TIME.sevenDay) setFromTime("7DAY");
    if (valueFilter === OPTION_FROM_TIME.oneDay) setFromTime("1DAY");
  };

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();

    return `${hours}:${minutes} ${day}-${month}-${year}`;
  };

  return (
    <>
      <div className="d-flex justify-content-between container-search  mb-3 mt-4">
        <div className="d-flex">
          <Form.Select
            aria-label="Default select example"
            className="select custom-select custon-select-mp me-1"
            onChange={(e) => handlerState(e.currentTarget.value)}
          >
            {OPTION_BUTTONS_STATE.map((radio) => (
              <option value={radio.value} key={radio.value}>
                {radio.name}
              </option>
            ))}
          </Form.Select>
          <Form.Select
            aria-label="Default select example"
            className="select custom-select custon-select-mp me-1"
            onChange={(e) => handlerFromTime(e.currentTarget.value)}
          >
            {OPTION_BUTTONS_FROM_TIME.map((radio) => (
              <option value={radio.value} key={radio.value}>
                {radio.name}
              </option>
            ))}
          </Form.Select>
        </div>

        <Form className="d-flex ms-4" onSubmit={sendSearch}>
          <Form.Control
            type="search"
            placeholder="Search by Id"
            className="me-2"
            aria-label="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Button
            variant="outline-success"
            type="submite"
            className="button-group mt-2"
          >
            Search
          </Button>
        </Form>
      </div>
      <Table striped bordered hover className="hoverable" responsive="sm">
        <thead>
          <tr>
            <th>Subasta</th>
            <th>Precio</th>
            <th>Moneda</th>
            <th>Fee</th>
            <th>Estado</th>
            <th>Fecha de creacion</th>
          </tr>
        </thead>
        {mpFilter ? (
          <tbody>
            <tr key={uuidv4()}>
              <td>{mpFilter.description}</td>
              <td>{mpFilter.transaction_details.total_paid_amount}</td>
              <td>{mpFilter.currency_id}</td>
              <td>{mpFilter.fee_details[0].amount}</td>
              <td>{mpFilter.status}</td>
              <td>
                {formatDateTime(mpFilter.charges_details[0].date_created)}
              </td>
            </tr>
          </tbody>
        ) : (
          mp && (
            <tbody>
              {mp?.map((e) => (
                <tr key={uuidv4()}>
                  <td>{e.description}</td>
                  <td>{e.transaction_details.total_paid_amount}</td>
                  <td>{e.currency_id}</td>
                  <td>{e.fee_details[0].amount}</td>
                  <td>{e.status}</td>
                  <td>{formatDateTime(e.charges_details[0].date_created)}</td>
                </tr>
              ))}
            </tbody>
          )
        )}
      </Table>
      {loaderSearch && <Loader />}
      <div className="d-flex justify-content-center">
        <Pagination>{items}</Pagination>
      </div>
    </>
  );
}
