import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Container } from "react-bootstrap";
import { helpHttp } from "../../helpers/helpHttp";
import { useSearchParams } from "react-router-dom";
import Error from "../404/Error";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/new/Posta.png";
import { ImgBackPage } from "../../util/constant/constant";

const ValidationMail = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();

  useEffect(() => {
    let userId = encodeURIComponent(searchParams.get("userId"));
    let token = encodeURIComponent(searchParams.get("token"));
    const URL_POST_CHANGE_PASSWORD = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_CONFIRM_EMAIL}?userId=${userId}&token=${token}`;
    let options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    setLoading(true);
    helpHttp()
      .get(URL_POST_CHANGE_PASSWORD, options)
      .then((res) => {
        if (res.error) {
          setError(true);
        }
        if (res.statusCode === 404) {
          setError(true);
        }
      });
    setLoading(false);
  }, []);

  const goMap = () => {
    navigate(`${process.env.REACT_APP_MAP_FRONT}`);
  };

  return (
    <ImgBackPage>
      {error ? (
        <Error />
      ) : (
        <Container>
          <div className="text-center p-5">
            <img src={logo} className="logo-login pointer" onClick={goMap} alt="logo"/>
          </div>
          <Form className="form z-depth-5">
            {loading ? (
              <></>
            ) : (
              <>
                <h1 className="mb-5">Mail validado correctamente</h1>
                <p>El mail fue validado correctamente</p>
                {/*  <div className="d-grid gap-2 p-4">
                  <button
                    size="lg"
                    className="waves-effect waves-light btn text-white rounded-pill btn-LogIn"
                    onClick={goMap}
                  >
                    Inicio
                  </button>
                </div> */}
              </>
            )}
          </Form>
        </Container>
      )}
    </ImgBackPage>
  );
};

export default ValidationMail;
