import Motorbike from "./Motorbike.png";
import Micro from "./Micro.png";
import Hatchback from "./Hatchback.png";
import SUV from "./SUV.png";
import Sedan from "./Sedan.png";
import Utility from "./Utility.png";

const imgs= [
  {
    id: 101,
    img: Motorbike,
    title: "Motorbike",
    nro: 0,
  },
  {
    id: 102,
    img: Micro,
    title: "Micro",
    nro: 1,
  },
  {
    id: 103,
    img: Hatchback,
    title: "Hatchback",
    nro: 2,
  },
  {
    id: 104,
    img: Sedan,
    title: "Sedan",
    nro: 4,
  },
  {
    id: 105,
    img: SUV,
    title: "SUV",
    nro: 3,
  },
  {
    id: 106,
    img: Utility,
    title: "Utility",
    nro: 5,
  },
];
export default imgs