import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { useEffect } from "react";
import { helpHttp } from "../../../helpers/helpHttp";
import { useState } from "react";
import Loader from "../../../common/loader/Loader";
import { useNavigate } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const countObjectsByMonth = (data) => {
  const objectsByMonth = {};

  data?.forEach((obj) => {
    const date = new Date(obj.time);
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    const key = `${year}-${month}`;

    if (!objectsByMonth[key]) {
      objectsByMonth[key] = 1;
    } else {
      objectsByMonth[key]++;
    }
  });

  const result = Object.keys(objectsByMonth).map((month) => ({
    mes: month,
    cantidad: objectsByMonth[month],
  }));

  return result;
};

export default function BarsChart() {
  const [auctionByMonth, setAuctionByMonth] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let navigate = useNavigate();

  useEffect(() => {
    const URL_AUCTIONS = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_GET_AUCTIONS}?page=1&pagesize=200`;
    let optionsReq = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    helpHttp()
      .get(URL_AUCTIONS, optionsReq)
      .then((res) => {
        if (res.err) {
          if ((res.status = 403))
            navigate(`${process.env.REACT_APP_MAP_FRONT}`);
          return;
        }
        /* console.log(res); */
        const byMonth = countObjectsByMonth(res.content);
        setAuctionByMonth(byMonth);
        setIsLoading(false);
      });
  }, []);

  const meses = auctionByMonth.map((item) => item.mes);
  const cantidades = auctionByMonth.map((item) => item.cantidad);

  const data = {
    labels: meses,
    datasets: [
      {
        label: "Subastas por Mes",
        data: cantidades,
        backgroundColor: "rgba(75, 192, 192, 0.5)",
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        min: 0,
      },
      x: {
        ticks: { color: "rgb(75, 192, 192, 1)" },
      },
    },
  };

  return <>{isLoading ? <Loader /> : <Bar data={data} options={options} />}</>;
}
