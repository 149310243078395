import Card from "react-bootstrap/Card";
import Nav from "react-bootstrap/Nav";
import { useState } from "react";
import PendingPayments from "./PendingPayments";
import Auctions from "./Auctions";
import Claims from "./Claims";
import Mp from "./Mp";
import { Container } from "react-bootstrap";
import logo from "../../assets/img/new/Posta.png";
import "./Index.css";
import background from "../../assets/img/new/half-fondo.png";
import Profile from "../../common/profile/Profile";
import Users from "./Users";
import Dashboard from "./Dashboard";

const imgBack = {
  backgroundImage: `url(${background})`,
  backgroundPosition: "bottom",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  height: "70vh",
  position: "relative",
};

const NAVIGATION_OPTIONS = {
  dashboard: "dashboard",
  pendingPayments: "pendingPayments",
  auctions: "auctions",
  mp: "mp",
  claims: "claims",
  users: "users",
};

const MAP_OPTIONS = [
  {
    option: "dashboard",
    name: "Inicio",
    component: <Dashboard />,
  },
  { option: "users", name: "Usuarios", component: <Users /> },
  {
    option: "pendingPayments",
    name: "Pagos pendientes",
    component: <PendingPayments />,
  },
  {
    option: "auctions",
    name: "Subastas",
    component: <Auctions />,
  },
  { option: "mp", name: "Mercado Pago", component: <Mp /> },
  {
    option: "claims",
    name: "Reclamos",
    component: <Claims />,
  },
];

export default function Index() {
  const [activeTab, setActiveTab] = useState("dashboard");
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(!show);

  const handleSelect = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  return (
    <div style={imgBack}>
      <div className="container_buttons">
        <button
          className="btn-floating btn-large waves-effect me-3 btn-menu"
          onClick={handleShow}
        >
          <i className="material-icons">menu</i>
        </button>
      </div>
      <Profile show={show} handleClose={handleShow} />
      <Container className="pb-3">
        <div className="text-center pt-5 pb-5">
          <img src={logo} alt="logo" width={150} />
        </div>
        <Card className="card-navigation hoverable z-depth-1">
          <Card.Header className="pt-4">
            <Nav variant="tabs" activeKey={activeTab} onSelect={handleSelect}>
              {MAP_OPTIONS.map((e) => (
                <Nav.Item
                  key={e.option}
                  className={`${
                    activeTab === e.option
                      ? "nav-item-active-navigation"
                      : "nav-item-navigation"
                  } `}
                >
                  <Nav.Link eventKey={e.option}>{e.name}</Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Card.Header>
          <Card.Body className="text-center">
            {MAP_OPTIONS.map(
              (e) =>
                activeTab === e.option && (
                  <div key={e.option}>{e.component}</div>
                )
            )}
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}
