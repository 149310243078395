import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { helpHttp } from "../../../helpers/helpHttp";
import Loader from "../../../common/loader/Loader";
import { useNavigate } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const LinesChart = () => {
  const [chartData, setChartData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  let navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const URL_USERS = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_GET_USER}?page=1&pagesize=200`;
        let options = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };
        const response = await helpHttp().get(URL_USERS, options);

        if (response.err) {
          if (response.status === 403) {
            navigate(`${process.env.REACT_APP_MAP_FRONT}`);
          }
          return;
        }

        if (isMounted) {
          /* console.log(response); */
          const data = generateChartData(response.content);
          setChartData(data);
          setIsLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  const generateChartData = (data) => {
    const currentDate = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(currentDate.getDate() - 7);

    const labels = [];
    const connections = [];

    for (let i = oneWeekAgo; i <= currentDate; i.setDate(i.getDate() + 1)) {
      const day = i.toLocaleDateString("es-AR", { weekday: "long" });
      labels.push(day);

      const dayConnections = data.filter((obj) => {
        const objDate = new Date(obj.date);
        return objDate.toLocaleDateString() === i.toLocaleDateString();
      });

      connections.push(dayConnections.length);
    }

    const result = {
      labels: labels,
      datasets: [
        {
          label: "Ultimas conexiones",
          data: connections,
          tension: 0.5,
          fill: true,
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
          pointRadius: 5,
          pointBorderColor: "rgba(255, 99, 132)",
          pointBackgroundColor: "rgba(255, 99, 132)",
        },
      ],
    };

    return result;
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0,
        },
      },
    },
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      {chartData.labels && chartData.datasets ? (
        <Line data={chartData} options={options} />
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default LinesChart;
