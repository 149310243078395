import React, { useCallback, useEffect, useState } from "react";
import GoogleMap from "../../common/google_map/GoogleMap";
import InfoWindow from "../../common/google_map/InfoWindow";
import Marker from "../../common/google_map/Marker";
import Message from "../../common/message/Message";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { useAuction } from "../../hooks/useAuction";
import Profile from "../../common/profile/Profile";
import LoaderLogo from "../../common/loader/LoaderLogo";

const MapSocket = () => {
  const [, updateState] = useState();
  const [, setTest] = useState([]);
  const [friends, setFriends] = useState([]);
  const [place, setPlace] = useState(null);
  const [myPosition, setMyPosition] = useState([null, null, true]);
  const [error, setError] = useState(null);
  const [connection, setConnection] = useState(null);
  const [stateConnection, setStateConnection] = useState([true, false]);
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleForceupdateMethod = useCallback(() => updateState({}), []);

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(
        `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_SOCKET}`,
        {
          accessTokenFactory: () => localStorage.getItem("token"),
        }
      )
      .withAutomaticReconnect()
      .build();

    newConnection.on("NewMessage", (message) => newMessage(message));

    setConnection(newConnection);
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((_) => {
          console.log("Connected!");
        })
        .catch((e) => console.log("Connection failed: ", e));
    }
  }, [connection]);

  //Join
  useEffect(() => {
    const join = async () => {
      if (stateConnection[1]) {
        connection.invoke("JoinGroup").then((_) => {});
        console.log("Joined");
        setLoader(false);
        setStateConnection([false, false]);
      } else {
        setTimeout(join, 6000);
      }
    };
    setTimeout(join, 5000);
  }, [stateConnection]);

  //Join2
  useEffect(() => {
    if (stateConnection[0]) {
      if (connection) {
        setStateConnection([true, true]);
      } else {
        setStateConnection([true, false]);
      }
    }
  }, [connection]);

  const newMessage = async (message) => {
    message.date = new Date();

    let tempFriends = friends;
    const index = tempFriends.findIndex((e) => e.userName === message.userName);

    if (index !== -1) {
      tempFriends[index] = message;
    } else {
      setFriends((oldArray) => [...oldArray, message]);
      tempFriends.push(message);
    }

    let now = new Date().getTime();
    let deleteOld = tempFriends.filter((e) => e.date.getTime() + 60000 > now);

    setFriends(deleteOld);

    setTest([]);
  };

  const handlerWindow = (children) => {
    const index = friends.findIndex(
      (e) => e.userName === children.currentTarget.getAttribute("alt")
    );
    friends[index].show = true;
    setPlace(friends[index]);
    var intro = document.getElementById("intro");

    if (intro.style.display === "none") {
      intro.style.display = "block";
    } else {
      intro.style.display = "none";
    }
  };

  const {
    joined,
    setPrice,
    setStartingPrice,
    setDays,
    setHours,
    setMinutes,
    setDescription,
    actualBit,
    conversation,
    auction,
    leave,
    sendBid,
    startBidding,
    join,
  } = useAuction();

  useEffect(() => {
    let userName = localStorage.getItem("user");
    let interval = null;
    interval = setInterval(() => {
      handleForceupdateMethod();
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const location = {
            message: "messageToSend",
            logitude: `${position.coords.longitude}`,
            latitude: `${position.coords.latitude}`,
            userName: userName,
          };

          if (myPosition[2]) {
            setMyPosition([
              parseFloat(location.latitude),
              parseFloat(location.logitude),
              false,
            ]);
          }

          if (connection) {
            connection.invoke("SendMessage", location).then((_) => {});
          }
        });
      } else {
        setError("No se pudo obtener la ubicación");
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [connection, myPosition]);

  return (
    <>
      {loader && <LoaderLogo />}
      {error && (
        <Message
          msg={`Error ${error.status}: ${error.statusText}`}
          bgColor="#dc3545"
        />
      )}
      {!loader && (
        <div className="container_buttons">
          <button className="btn btn-info me-3" onClick={handleShow}>
            Perfil
          </button>
        </div>
      )}
      <Profile show={show} handleClose={handleClose} />

      {!loader && friends && (
        <div style={{ height: "93vh", width: "100%" }}>
          <GoogleMap
            defaultZoom={15}
            defaultCenter={[myPosition[0], myPosition[1]]}
            yesIWantToUseGoogleMapApiInternals={true}
          >
            {friends.map((location) => (
              <Marker
                id={myPosition.userName}
                key={location.userName}
                text={location.userName}
                lat={parseFloat(location.latitude)}
                lng={parseFloat(location.logitude)}
                show={location.show}
                onClick={handlerWindow}
                place={location}
              ></Marker>
            ))}

            <div id="intro" style={{ display: "none" }}>
              {place ? (
                <InfoWindow
                  place={place}
                  show={show}
                  handleClose={handleClose}
                  joined={joined}
                  join={join}
                  auction={auction}
                  actualBit={actualBit}
                  sendBid={sendBid}
                  setPrice={setPrice}
                  setStartingPrice={setStartingPrice}
                  setDays={setDays}
                  setHours={setHours}
                  setMinutes={setMinutes}
                  setDescription={setDescription}
                  startBidding={startBidding}
                  leave={leave}
                  conversation={conversation}
                  auctions={auction}
                  close={handlerWindow}
                />
              ) : null}
            </div>
          </GoogleMap>
        </div>
      )}
    </>
  );
};

export default MapSocket;
