import React from 'react'
import { Button, Modal } from 'react-bootstrap'

const ModalConfirm = ({ modalConfirm, setModalConfirm, text, action }) => {
  return (
    <Modal show={modalConfirm} onHide={() => setModalConfirm(false)}>
      <Modal.Header closeButton>
        <Modal.Title className="fs-3">Confirmacion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-loader-search-bidId">{text}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setModalConfirm(false)}>
          Cancelar
        </Button>
        <Button variant="success" className="ms-3" onClick={action}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalConfirm;
