import backgroundPage from "../../assets/img/new/half-fondo.png";
import backgroundIndex from "../../assets/img/new/fondo.jpg";

export function ImgBackPage({ children }) {
  const imgBack = {
    backgroundImage: `url(${backgroundPage})`,
    backgroundPosition: "bottom",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "70vh",
    position: "relative",
  };
  return <div style={imgBack}>{children}</div>;
}

export function ImgBackIndex({children}) {
  const imgBack = {
    backgroundImage: `url(${backgroundIndex})`,
    backgroundPosition: "top",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100vh",
    minHeight: "100%",
    position: "relative",
  };
  return <div style={imgBack}>{children}</div>;
}
