import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Loader from "../../common/loader/Loader";

const ModalAuction = ({ modalViewMore, setModalViewMore, loaderSearchId, auction }) => {
  return (
    <Modal show={modalViewMore} onHide={() => setModalViewMore(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-3">Informacion de la Subasta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-loader-search-bidId">
            {loaderSearchId && <Loader />}
            {auction && (
              <div className="fs-5">
                <div>
                  <span className="font-weight-bold">Dueño: </span>
                  {auction.username}
                </div>
                <div>
                  <span className="font-weight-bold">Precio inicial: </span>
                  {auction.initialPrice}
                </div>
                <div>
                  <span className="font-weight-bold">Precio final: </span>
                  {auction.finalPrice}
                </div>
                <div>
                  <span className="font-weight-bold">Ganador: </span>
                  {auction.winnerUserName}
                </div>
                <div>
                  <span className="font-weight-bold">Estado del pago: </span>
                  {auction.paid ? "pagado" : "no pagado"}
                </div>
                <div>
                  <span className="font-weight-bold">Estado del retiro: </span>
                  {auction.moneyRetired
                    ? "ya retiro el dinero"
                    : "no retiro el dinero"}
                </div>
                <div>
                  <span className="font-weight-bold">Patente: </span>
                  {auction.plate}
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalViewMore(false)}>
            cerrar
          </Button>
        </Modal.Footer>
      </Modal>
  )
}

export default ModalAuction;
