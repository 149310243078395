import "./App.css";
import FormRL from "./page/form/FormRL";
import Map from "./page/map_request/Map.js";
import Admin from "./page/admin/index";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { SessionProvider } from "./context/SessionContext";
import { ProtectedRouteNoSession } from "./util/protected_route/ProtectedRouteNoSession";
import { ProtectedRouteWhitSession } from "./util/protected_route/ProtectedRouteWhitSession";
import MapSocket from "./page/map_socket/MapSocket";
import Error from "./page/404/Error";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import Test from "./page/test/test";
import ChangePassword from "./page/change_password/change_password";
import EmailChangePassword from "./page/change_password/email_change_password";
import ValidationMail from "./page/validation-mail/ValidationMail";
import Index from "./page/index";
import ValidationPassword from "./page/validation-password/ValidationPassword";


function App() {
  return (
    <div>
      <SessionProvider>
        <BrowserRouter>
          <NotificationContainer />
          <Routes>
            <Route element={<ProtectedRouteWhitSession />}>
              <Route index element={<Index />} />
              <Route
                path={process.env.REACT_APP_REGISTER_FRONT}
                element={<FormRL />}
              />
              <Route
                path={process.env.REACT_APP_LOGIN_FRONT}
                element={<FormRL />}
              />
            </Route>
            <Route element={<ProtectedRouteNoSession />}>
              <Route path={process.env.REACT_APP_MAP_FRONT} element={<Map />} />
              <Route path={process.env.REACT_APP_ADMIN_FRONT} element={<Admin />} />
              <Route
                path={process.env.REACT_APP_MAP_SOCKET_FRONT}
                element={<MapSocket />}
              />
              <Route path="test" element={<Test />} />
            </Route>
            <Route path="*" element={<Error />} />
            <Route path="change-password" element={<ChangePassword />} />
            <Route path="validation-mail" element={<ValidationMail />} />
            <Route path={process.env.REACT_APP_CONFIRM_PASSWORD} element={<ValidationPassword />} />
            <Route
              path={process.env.REACT_APP_CHANGE_PASSWORD_FRONT}
              element={<EmailChangePassword />}
            />
          </Routes>
        </BrowserRouter>
      </SessionProvider>
    </div>
  );
}

export default App;
