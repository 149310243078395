import { Navigate, Outlet } from "react-router-dom";


export const ProtectedRouteWhitSession = ({children}) => {

    let tokenExist = localStorage.getItem('token') ? true : false;
    
    if (tokenExist) {
        return <Navigate to={`${process.env.REACT_APP_MAP_FRONT}`} replace />;
    }

  return children ? children : <Outlet />;
};