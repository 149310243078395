import { v4 as uuidv4 } from "uuid";

/* import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button"; */
import ListGroup from "react-bootstrap/ListGroup";
import "./InfoWindow.css";
import { Form } from "react-bootstrap";
import { useState } from "react";


const InfoWindow = ({
  place,
  auction,
  actualBit,
  finishAuction,
  takeFree,
  setDescription,
  sendBid,
  setPrice,
  setStartingPrice,
  setDays,
  setHours,
  setMinutes,
  finish_bid,
  startBidding,
  freeParking,
  getFreeParking_,
  free,
  inAnAuction,
  quitFromBid_,
}) => {
  let auctionInfo = auction.filter((e) => e.Username === place.userName);
  let user = localStorage.getItem("user");
  let idAuction = localStorage.getItem("id-auction");
  const [fixCheck, setFixCheck] = useState(true);

  const leaveAuction = (id, user) => {
    setFixCheck(false)
    localStorage.removeItem("id-auction");
    quitFromBid_(id, user);
    idAuction = localStorage.getItem("id-auction");
  };

  const sendBid_ = (id) => {
    setFixCheck(true);
    sendBid(id)
  }

  const close = () => {
    var intro = document.getElementById("intro");
    intro.style.display = "none";
  };

  const getTimeFormat = (min, date, id) => {
    if (min !== undefined) {
      let countDownDate = new Date().getTime();

      if (typeof min !== "string") {
        let minInt = parseInt(min);
        let dateFormat = date.toJSON();
        let dataMilliseconds = new Date(`${dateFormat}`).getTime();
        dataMilliseconds = dataMilliseconds + minInt * 60000;
        countDownDate = dataMilliseconds;
      } else {
        countDownDate = new Date(min).getTime();
      }

      const now = new Date().getTime();
      const distance = (countDownDate - now) / 1000;

      const seconds = Math.floor(distance % 60);
      const minutes = Math.floor((distance % 3600) / 60);
      const hours = Math.floor((distance % (3600 * 24)) / 3600);
      const days = Math.floor(distance / (3600 * 24));

      const secondsStr = makeHumanReadable(seconds, "segundo");
      const minutesStr = makeHumanReadable(minutes, "minuto");
      const hoursStr = makeHumanReadable(hours, "hora");
      const daysStr = makeHumanReadable(days, "dia");

      let result = `${daysStr}${hoursStr}${minutesStr}${secondsStr}`.replace(
        /,\s*$/,
        ""
      );
      if (result === "") {
        finishAuction(id, false);
        return "Finalizado";
      } else {
        return `${daysStr}${hoursStr}${minutesStr}${secondsStr}`.replace(
          /,\s*$/,
          ""
        );
      }
    }
  };

  function makeHumanReadable(num, singular) {
    return num > 0
      ? num + (num === 1 ? ` ${singular}, ` : ` ${singular}s, `)
      : "";
  }

  return (
    <>
      {place.active ? (
        {/* <div className="close infoWindowStyle" id="intro">
          <div className="d-flex justify-content-between mb-3">
            <div style={{ fontSize: 18 }} className="text-uppercase pt-2">
              <strong>Free</strong>
            </div>
            <button className="btn btn-outline-danger btn-sm" onClick={close}>
              X
            </button>
          </div>
          <div className="d-grid gap-2">
            <button className="btn btn-info" onClick={() => takeFree(place.id)}>
              Tomar lugar
            </button>
          </div>
        </div> */}
      ) : (
        <div className="close infoWindowStyle" id="intro">
          <div className="d-flex justify-content-between mb-3">
            <div style={{ fontSize: 18 }} className="text-uppercase pt-2">
              <strong>{place.userName}</strong>
            </div>
            <button className="btn btn-outline-danger btn-sm" onClick={close}>
              X
            </button>
          </div>
          <div className="d-flex justify-content-between overflow">
            <div>
              {/* {place.userName === user && !inAnAuction[2] ? (
                <div className="d-grid gap-2">
                  <button
                    className="btn btn-info"
                    onClick={() => freeParking()}
                  >
                    Dejar Lugar
                  </button>
                </div>
              ) : (
                <></>
              )} */}
              <ListGroup as="ul" className="auctionMap">
                {auctionInfo.map((a) => (
                  <div key={a.Id}>
                    <ListGroup.Item as="li" active>
                      <strong>
                        Subasta {a.Id} ( {a.Username} ) ( {a.description} ){" "}
                        <br />
                      </strong>{" "}
                      Duracion:{" "}
                      {a.minutes
                        ? getTimeFormat(parseInt(a.minutes), a.date, a.Id)
                        : getTimeFormat(a.Time, a.date, a.Id)}{" "}
                      <br /> {a.id ? "Precio Inicial:" : "Precio:"}{" "}
                      {a.price ? a.price : a.finalPrice}$
                    </ListGroup.Item>
                    {actualBit.map((b) => (
                      <div key={uuidv4()}>
                        {b.id.trim() === `${a.Id}` ? (
                          <ListGroup.Item as="li">
                            <div key={uuidv4()}>
                              <strong>Precio:</strong>
                              {b.actualPrice}$ - <strong>Usuario:</strong>
                              {b.actualUser}
                            </div>
                          </ListGroup.Item>
                        ) : null}
                      </div>
                    ))}
                   {/*  {place.userName !== user &&
                    a.Id === idAuction &&
                    fixCheck ? (
                      <div className="d-grid gap-2">
                        <button
                          className="btn btn-danger"
                          onClick={() => leaveAuction(idAuction, user)}
                        >
                          Dejar subasta
                        </button>
                      </div>
                    ) : null}
                    {place.userName !== user ? (
                      <div className="input-field col s6">
                        <input
                          type="number"
                          placeholder="precio"
                          onChange={(e) => setPrice(e.target.value)}
                        />
                        <button
                          size="lg"
                          className="waves-effect waves-light btn text-white btn-LogIn"
                          onClick={() => sendBid_(a.Id)}
                        >
                          Pujar
                        </button>
                      </div>
                    ) : (
                      <></>
                    )} */}
                    {/* {place.userName === user ? (
                      <div className="d-grid gap-2">
                        <button
                          className="btn btn-danger"
                          onClick={() => finishAuction(a.Id, true)}
                        >
                          Cancelar subasta
                        </button>
                      </div>
                    ) : null} */}
                  </div>
                ))}
              </ListGroup>
              {/* {place.userName === user ? (
                <Form onSubmit={startBidding}>
                  <h5 className="mt-4">SUBASTAR</h5>
                  <div className="input-field col s6">
                    <input
                      type="number"
                      placeholder="Precio inicial"
                      onChange={(e) => setStartingPrice(e.target.value)}
                      required
                      min="1"
                    />
                  </div>
                  <div className="input-field col s6">
                    <input
                      type="number"
                      placeholder="Dias"
                      onChange={(e) => setDays(e.target.value)}
                      min="0"
                    />
                  </div>
                  <div className="input-field col s6">
                    <input
                      type="number"
                      placeholder="Horas"
                      onChange={(e) => setHours(e.target.value)}
                      min="0"
                    />
                  </div>
                  <div className="input-field col s6">
                    <input
                      type="number"
                      placeholder="Minutos"
                      onChange={(e) => setMinutes(e.target.value)}
                      min="1"
                    />
                  </div>
                  <div className="input-field col s6">
                    <input
                      as="textarea"
                      placeholder="Descripcion"
                      onChange={(e) => setDescription(e.target.value)}
                      required
                    />
                  </div>
                  <div className="d-grid gap-2 p-4">
                    <button
                      size="lg"
                      type="submit"
                      className="waves-effect waves-light btn text-white rounded-pill btn-LogIn"
                    >
                      Subastar
                    </button>
                  </div>
                </Form>
              ) : (
                <></>
              )} */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InfoWindow;
