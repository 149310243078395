import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { helpHttp } from "../../../helpers/helpHttp";
import Loader from "../../../common/loader/Loader";
import { useNavigate } from "react-router-dom";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = () => {
  const [chartData, setChartData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  let navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    const URL_AUCTIONS = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_GET_AUCTIONS}?page=1&pagesize=200`;
    let optionsReq = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    helpHttp()
      .get(URL_AUCTIONS, optionsReq)
      .then((res) => {
        if (res.err) {
          if ((res.status = 403))
            navigate(`${process.env.REACT_APP_MAP_FRONT}`);
          return;
        }
        /* console.log(res); */
        if (isMounted) {
          const data = countObjectsByBrand(res.content);
          setChartData(data);
          setIsLoading(false);
        }
      });

    return () => {
      isMounted = false;
    };
  }, []);

  const countObjectsByBrand = (data) => {
    const objectsByBrand = {};

    data?.forEach((obj) => {
      const brand = obj.brand;

      if (!objectsByBrand[brand]) {
        objectsByBrand[brand] = 1;
      } else {
        objectsByBrand[brand]++;
      }
    });

    const result = {
      labels: Object.keys(objectsByBrand),
      datasets: [
        {
          label: "Cantidad de Objetos por Marca",
          data: Object.values(objectsByBrand),
          backgroundColor: [
            "rgba(255, 99, 132, 0.5)",
            "rgba(0, 164, 236, 0.5)",
            "rgba(161, 100, 253, 0.5)",
            "rgba(255, 154, 51, 0.5)",
            "rgba(103, 152, 255, 0.5)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(0, 164, 236, 1)",
            "rgba(161, 100, 253, 1)",
            "rgba(255, 154, 51, 1)",
            "rgba(103, 152, 255, 1)",
          ],
          borderWidth: 1,
        },
      ],
    };

    return result;
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 2,
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      {chartData.labels && chartData.datasets ? (
        <Pie data={chartData} options={options} />
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default PieChart;
