import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  ButtonGroup,
  Form,
  Pagination,
  Table,
  ToggleButton,
} from "react-bootstrap";
import { helpHttp } from "../../helpers/helpHttp";
import Loader from "../../common/loader/Loader";

const OPTION_SEARCH = {
  UseName: "1",
  Bidid: "2",
};

const OPTION_BUTTONS = [
  { name: "UseName", value: OPTION_SEARCH.UseName },
  { name: "Bidid", value: OPTION_SEARCH.Bidid },
];

export default function Claims() {
  const [claims, setClaims] = useState([{ id: "asd" }]);
  const [activePage, setActivePage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const [radioValue, setRadioValue] = useState(OPTION_SEARCH.UseName);
  const [search, setSearch] = useState("");
  const [addSearch, setAddSearch] = useState("");
  const [loaderSearch, setLoaderSearch] = useState(true);

  let active = activePage;
  let items = [];
  for (let number = 1; number <= maxPages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === active}
        onClick={() => setActivePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  useEffect(() => {
    setLoaderSearch(true);
    const URL_NEARBY = `${process.env.REACT_APP_API_DOMAIN}${
      process.env.REACT_APP_GET_COMENT
    }?page=${activePage ? activePage : 1}&pagesize=30${addSearch}`;
    let options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    helpHttp()
      .get(URL_NEARBY, options)
      .then((res) => {
        setClaims(res);
        setActivePage(res.pageNumber);
        setMaxPages(res.totalPages ? res.totalPages : 1);
        setLoaderSearch(false);
      });
  }, [activePage, addSearch]);

  const sendSearch = (e) => {
    e.preventDefault();
    let option;
    if (radioValue === OPTION_SEARCH.UseName) option = "userName";
    if (radioValue === OPTION_SEARCH.Bidid) option = "bidid";

    setAddSearch(`&${option}=${search}`);
  };

  return (
    <>
      <div className="d-flex justify-content-end container-search  mb-3 mt-4">
        <ButtonGroup>
          {OPTION_BUTTONS.map((radio, idx) => (
            <ToggleButton
              key={idx}
              id={`radio-${idx}`}
              type="radio"
              variant="secondary"
              name="radio"
              value={radio.value}
              checked={radioValue === radio.value}
              onChange={(e) => setRadioValue(e.currentTarget.value)}
              className="button-group"
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ButtonGroup>

        <Form className="d-flex ms-4" onSubmit={sendSearch}>
          {radioValue !== OPTION_SEARCH.Estado && (
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          )}
          <Button
            variant="outline-success"
            type="submite"
            className="button-group"
          >
            Search
          </Button>
        </Form>
      </div>
      <Table striped bordered hover className="hoverable">
        <thead>
          <tr>
            <th>Usuario</th>
            <th>Mensaje</th>
            <th>id de subasta</th>
          </tr>
        </thead>
        {claims && (
          <tbody className="text-center">
            {claims.content?.map((claim) => (
              <tr key={claim.id}>
                <td>{claim.userName}</td>
                <td className="col-message">{claim.content}</td>
                <td>{claim.bid}</td>
              </tr>
            ))}
          </tbody>
        )}
        {claims.content?.length < 1 ? (
          <tbody className="text-center">
            <tr>
              <td colSpan="3">No hay datos</td>
            </tr>
          </tbody>
        ) : null}
      </Table>
      {loaderSearch && <Loader />}
      <div className="d-flex justify-content-center">
        <Pagination>{items}</Pagination>
      </div>
    </>
  );
}
