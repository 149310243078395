import React from 'react';
import PropTypes from 'prop-types';
import './Marker.css';
import MarketCarFree from "../../assets/img/free.png";
import MarketCar from "../../assets/img/MarketCar.png";

const Wrapper = (props) => (
  <div {...props}>
    <img
      src={props.free ? MarketCarFree : MarketCar}
      alt="MarketCar"
      width={40}
    />
  </div>
);

const Marker = ({ text, free, onClick, children, nearby, color, iam }) => (
  <>
    {iam && <strong className="textName">tú</strong>}
    <Wrapper
      className={`${nearby && "nearby"} ${iam && "iam"} wrapper ${color}`}
      alt={text}
      free={free}
      id={iam}
      onClick={onClick}
      p={<p>tú</p>}
    />
    {children}
  </>
);

Marker.defaultProps = {
  onClick: null,
};

Marker.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};

export default Marker;