import { useEffect } from "react";
import { createContext, useState } from "react";
import { helpHttp } from "../helpers/helpHttp";

const SessionContext = createContext();

const SessionProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [chargingToken, setChargingToken] = useState(false);

  useEffect(() => {
    const fetchNewToken = async () => {
      try {
        let options = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        };
        let url = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_RETOKEN}`;

        await helpHttp()
          .get(url, options)
          .then((res) => {
            if (!res.err) {
              localStorage.removeItem("token");
              localStorage.setItem("token",res.token);
              setToken(res.token);
            } else {
              setToken(null);
              setUser(null);
            }
          });
      } catch (error) {
        console.log("Error al obtener el nuevo token:", error);
      }
    };

    fetchNewToken();

    const tokenRegenerationInterval = setInterval(fetchNewToken, 3300000);

    return () => clearInterval(tokenRegenerationInterval);
  }, []);

  const logout = () => {
    let data = { logitud: `${null}`, latitud: `${null}` };

    let options2 = {
      body: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    let url2 = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_UPDATE_LOCATION}`;

    helpHttp()
      .put(url2, options2)
      .then((res) => {
        if (!res.err) {
          setToken(null);
          setUser(null);
        } else {
          console.log(res.err);
        }
      });

    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.href = `${process.env.REACT_APP_LOGIN_FRONT}`;
  };

  const data = { token, setToken, logout, user, setUser };

  return (
    <SessionContext.Provider value={data}>{children}</SessionContext.Provider>
  );
};

export { SessionProvider };

export default SessionContext;
