import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import logo from '../../assets/img/404.png';


export default function Error() {

    let navigate = useNavigate();

    const back = () =>{
      navigate(`${process.env.REACT_APP_LOGIN_FRONT}`);
    }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '90.5vh'
      }}
    >
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid xs={6}>
            <Typography variant="h1">
              404
            </Typography>
            <Typography variant="h6">
              La pagina que buscas no existe.
            </Typography>
            <Button variant="contained" onClick={back} className="mt-3">Atras</Button>
          </Grid>
          <Grid xs={6}>
            <img
              src={logo}
              alt="404"
              width={500} height={250}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}