import LinesChart from "./graphic/LinesChart";
import BarsChart from "./graphic/BarsChart";
import PiesChart from "./graphic/PiesChart";

function Dashboard() {
  return (
    <div className="d-flex gap-4 flex-wrap justify-content-center">
      <div>
        <div
          className="bg-light mx-auto px-2 border border-2 border-graphic hoverable grafic"
          style={{ width: "450px", height: "230px" }}
        >
          <LinesChart />
        </div>
      </div>
      <div>
        <div
          className="bg-light mx-auto px-2 border border-2 border-graphic hoverable grafic"
          style={{ width: "450px", height: "230px" }}
        >
          <BarsChart />
        </div>
      </div>
      <div>
        <div
          className="bg-light mx-auto border border-2 border-graphic hoverable grafic"
          style={{ width: "450px", height: "250px" }}
        >
          <div style={{ width: "100%", height: "100%", padding: "10px 0" }}>
            <PiesChart />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Dashboard;
