import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { helpHttp } from "../../helpers/helpHttp";
import LoaderLogo from "../../common/loader/LoaderLogo";

/* const FORM_ID = "payment-form"; */

export default function Test() {
  /* const { id } = useParams(); // id de producto
  const [preferenceId, setPreferenceId] = useState(null); */

  const [url] = useState(null);
  /* const [loading, setLoading] = useState(true);
  const [mp, setMp] = useState(null); */
  const [searchParams] = useSearchParams();

  const URL_MP_PAY = `${process.env.REACT_APP_API_DOMAIN}${
    process.env.REACT_APP_MP_PAY
  }?id=${localStorage.getItem("id-auction")}`;
  let options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  useEffect(() => {
    helpHttp()
      .post(URL_MP_PAY, options)
      .then(() => {});
    window.location.href = `${process.env.REACT_APP_MAP_FRONT}`;
  }, []);

  /* const getColectorId = () => {
    console.log(searchParams.get("collection_id"));
    return searchParams.get("collection_id");
  };

  const goToPay = () => {
    window.open(url, "_blank");
  }; */
  return (
    <>
      <LoaderLogo />
    </>
  );
}
